$(function () {

    function getScrollBarSize () {
        var inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "100%";

        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.height = "100px";
        outer.style.overflow = "hidden";
        outer.appendChild (inner);

        document.body.appendChild (outer);

        var w1 = inner.offsetWidth;
        var h1 = inner.offsetHeight;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
        var h2 = inner.offsetHeight;
        if (w1 == w2) w2 = outer.clientWidth;
        if (h1 == h2) h2 = outer.clientHeight;

        document.body.removeChild (outer);

        return (h1 - h2);
    };


    var barHeight = getScrollBarSize();


    if ($('section.page-start').length > 0) {
        $(window).on('scroll resize', function () {
            toggleStickyBullets();
        });
    }

    function toggleStickyBullets(){
        if ($(window).width() <= 991) {
            // 'window.outerHeight'?
            if ((window.innerHeight + $(window).scrollTop()) >= ($('.homepage-slider').height() + $('.homepage-slider').offset().top)) {
                $('.homepage-slider .animated-pagination').css('position', 'absolute');
            } else {
                $('.homepage-slider .animated-pagination').css('position', 'fixed');
            }

        } else {
            $('.homepage-slider .animated-pagination').css('position', 'absolute');
        }
    }

    // Slider eyecatcher
    if(!window.getCookie('homepageSliderEyecatcherClosed')) {
        $(".homepage-slider .eyecatcher").show();
    }

    $('.homepage-slider .eyecatcher .close').on('click', function () {
        window.setCookie("homepageSliderEyecatcherClosed", true, 1);

        $('.homepage-slider .eyecatcher').addClass('closed');
    });
});
