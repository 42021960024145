/**
 * Please, PLEASE READ THIS
 * I @peb stole this from titlis and fitted it to my needs
 * LOL Nobody ever reads this
 */


var go4map = (function () {

    var data = {
        selectedId: null,
        data: null,
        activeMarker: null,
        markers: [],
        desktop: true,
        mode: "both",
        clusterer: null,
        activeMarkers: [],
        updateTimeout: null,
        sortedCompanies: [],
        searchBox: null
    };

    var config = {
        marker: '/static/grethers-pastilles/img/map/marker.png',
        markerHover: '/static/grethers-pastilles/img/map/marker_active.png'
    };

    var urlParameters = {
        id: null,
        text: null,
        lat: null,
        lng: null,
        z: null,
        mode: null
    };

    var localGoogleKey = "AIzaSyD21Rlo3VADNj9cPTYvnvWG1DZDpRRZypU";

    /**
     * codepart for the plzbox
     */
    function initPlzBox() {
        $(".plzbox").each(function() {
            var $box = $(this),
                $input = $box.find("input"),
                $button = $box.find("button"),
                $feedback = $box.find(".invalid-feedback");

            function submitForm() {
                var value = $input.val(),
                    url = $button.attr("data-href");

                if (value.length == 4) {
                    $.getJSON("https://maps.googleapis.com/maps/api/geocode/json?address="+encodeURIComponent(value)+"&region=ch&country=ch&key=" + localGoogleKey, function(geoData) {
                        $feedback.hide();
                        if (geoData.status == "OK") {
                            if (geoData.results.length >= 1) {
                                var result = geoData.results[0],
                                    lat = result.geometry.location.lat,
                                    lng = result.geometry.location.lng,
                                    redirect = url + "#lat="+lat+"&lng="+lng+"&text="+value + "&z=13";

                                window.location.href = redirect;

                                return false;
                            }
                        }else {
                            $feedback.show();
                        }
                    });
                    return false;
                }

                $feedback.show();

                return false;
            }

            $button.on("click", submitForm);
            $box.find("form").on("submit", submitForm);
        });
    }
    

    /**
     * codepart for the rest of the map
     */

    function init() {

        var branchInputHeight = $('#search-container-input').height();
        $('#search-container-result').css('height', '');

        if ( $(window).width() > 990 ) {
            var branchResultHeight = ($(window).height() - branchInputHeight) - 120;
            $('#search-container-result').css('height', branchResultHeight);
        }
        if ( $(window).width() > 1200 ) {
            var branchResultHeight = ($(window).height() - branchInputHeight) - 140;
            $('#search-container-result').css('height', branchResultHeight);
        }


        if ( $(window).width() < 768 ) {
            data.desktop = false;
        }

        if ($("body").hasClass("editmode")) {
            return;
        }

        getParameterFromUrl();
        readData();

        if (data.desktop) {
            initMap();
            showDetails();
        }

        bindEvents();

        if (!data.desktop) {
            sortMobileResults();
            filterMobileResults();
        }

        window.mapData = data;
    }

    function readData() {
        data.data = window.data;

        window.mapdata = data;
    }

    function showDetails() {
        var $companyDetail = $("#companyDetail"),
            company = data.data[data.selectedId];

        if (data.selectedId) {
            // $companyDetail.show();
            // fillDetailBlock($companyDetail, company);
            setResultBlocks([company]);
        }
        else {
            $("#search-container-result .added").remove();
            // $companyDetail.hide();
        }
    }

    function setResultBlocks(companies) {
        var $firstCard = $("#companyDetail"),
            $companyDetailContainer = $("#search-container-result");

        $("#search-container-result .added").remove();
        for (var i = 0; i < companies.length; i++) {

            var company = companies[i],
                $newCard = $firstCard.clone();

            if (company) {
                // remove all old cards
                fillDetailBlock($newCard, company);
                $newCard.addClass("added");
                $newCard.removeClass("default-card");
                $newCard.show();
                $newCard.attr("id", "company-" + company.id);
                $companyDetailContainer.append($newCard);
            }
        }
    }

    function scrollToActiveCard() {
        if (!data.selectedId) {
            return;
        }

        if ($("#company-" + data.selectedId).length <= 0) {
            data.selectedId = null;
            urlParameters.id = null;
            data.activeMarker = null;
            updateUrl();
            updateMarkers();

            return;
        }

        $(".card-active").removeClass("card-active");
        $("#company-" + data.selectedId).addClass("card-active");
        $("#search-container-result").animate({
            scrollTop: $("#company-" + data.selectedId).offset().top - $("#search-container-result").offset().top + $("#search-container-result").scrollTop()
        });
    }

    function updateResultsBasedOnMap() {
        if (data.map) {
            var bounds = data.map.getBounds(),
                companiesInBounds = [];

            if (data.map.getZoom() >= 10) {
                for (var i in data.data) {
                    if (isNaN(i))
                        continue;

                    var company = data.data[i];
                    var show = false;

                    if (data.mode == "both" || data.mode == "apotheke" && company.apotheke || data.mode == "drogerie" && company.drogerie) {
                        show = true;
                    }

                    var latLng = new google.maps.LatLng(company.lat, company.lng);

                    if (bounds.contains(latLng) && show) {
                        companiesInBounds.push(company);
                    }
                }

                setResultBlocks(companiesInBounds);
            }
            else {
                data.selectedId = null;
                urlParameters.id = null;
                updateUrl();
                updateMarkers();
                setResultBlocks([]);
            }
        }
    }

    function fillDetailBlock($companyDetail, company) {
        var $firma = $companyDetail.find(".card-title"),
            $zusatz = $companyDetail.find(".zusatz"),
            $street = $companyDetail.find(".adresse"),
            $postfach = $companyDetail.find(".postfach"),
            $plzort = $companyDetail.find(".plzort"),
            $addressLink = $companyDetail.find(".address-link");

        fillElement($firma, company.firma);
        fillElement($zusatz, company.zusatz);
        fillElement($street, company.adresse);
        fillElement($postfach, company.postfach);
        fillElement($plzort, company.plzort);
        $addressLink.attr("href","https://www.google.de/maps/place/" + encodeURIComponent(company.adresse + ", " + company.plzort));
    }

    function fillElement($element, value) {
        $element.css("display", value ? "inline" : "none").html(value + "<br />");

        return $element;
    }

    function updateGUI() {
        if (!data.desktop)
            return;

        updateResultsBasedOnMap();
        scrollToActiveCard();
        updateMarkers();
    }

    function updateAndClusterMarkers() {
        if (!data.desktop)
            return;

        if (data.map) {

            var bounds = new google.maps.LatLngBounds(),
                foundCurrent = false;
            // update toggler
            $("#radioAll").prop("checked", false);
            $("#radioDrogerien").prop("checked", false);
            $("#radioApotheken").prop("checked", false);
            if (data.mode == "both") {
                $("#radioAll").prop("checked", true);
            }
            else if (data.mode == "apotheke") {
                $("#radioApotheken").prop("checked", true);
            }
            else {
                $("#radioDrogerien").prop("checked", true);
            }

            data.activeMarkers = [];


            for (var i in data.data) {
                if (isNaN(i))
                    continue;

                var company = data.data[i];
                var marker = company.marker;
                var show = false;

                if (data.mode == "both" || data.mode == "apotheke" && company.apotheke || data.mode == "drogerie" && company.drogerie) {
                    show = true;
                }

                if (show) {
                    bounds.extend(marker.getPosition());
                    data.activeMarkers.push(marker);

                    if (company.id == data.selectedId) {
                        foundCurrent = true;
                    }
                }
            }

            if (!foundCurrent) {
                data.selectedId = null;
                urlParameters.id = null;
            }


            if (urlParameters.z && urlParameters.lat && urlParameters.lng) {
                data.map.setCenter({
                    lat: parseFloat(urlParameters.lat),
                    lng: parseFloat(urlParameters.lng)
                });

                data.map.setZoom(parseFloat(urlParameters.z));
            }
            else {
                data.map.fitBounds(bounds);
            }

            data.searchBox.setBounds(data.map.getBounds());

            if (data.markerCluster) {
                data.markerCluster.clearMarkers();
            }

            // Cluster Styles
            var mcOptions = {
                styles: [{
                    url: "/static/grethers-pastilles/img/map/m1.png",
                    width: 44,
                    height: 44,
                    textColor: '#ffffff',
                    textSize: 13
                },
                    {
                        url: "/static/grethers-pastilles/img/map/m2.png",
                        height: 44,
                        width: 44,
                        textColor: '#ffffff',
                        textSize: 15
                    }]
            };
            data.markerCluster = new MarkerClusterer(data.map, data.activeMarkers, mcOptions);

            // google.maps.event.addListener(data.markerCluster, 'clusterclick', function (cluster) {
            // });
            updateMarkers();
        }
    }

    function updateUrl() {
        var hash = '';

        for (var key in urlParameters) {
            if (["id", "text", "lat", "lng", "z", "mode"].indexOf(key) !== -1 && urlParameters[key]) {
                hash += key + '=' + urlParameters[key] + '&';
            }
        }

        history.replaceState(undefined, undefined, "#" + hash);
    }

    /**
     * gets the Parameter from the url
     * accordionid -> opens the accordion part
     * downloadoverlay -> opens the overlay
     */
    var getParameterFromUrl = function () {

        var hash = location.hash.replace(/^.*?#/, '');
        var pairs = hash.split('&');

        $.each(pairs, function( index, value ) {

            var key = value.split('=')[0]
            var keyValue = value.split('=')[1]

            if(key !== '' && keyValue !== '') {
                if (key == "text") {
                    keyValue = decodeURI(keyValue);
                }
                urlParameters[key] = keyValue;
            }
        });

        if (urlParameters.id) {
            data.selectedId = urlParameters.id;
        }

        if (urlParameters.text) {
            $("#cd-branch-input-desktop").val(urlParameters.text);
            $("#cd-branch-input-mobile").val(urlParameters.text);
        }

        updateGUI();

    };


    function updateMarkers() {
        for(var i in data.markers) {
            if (isNaN(i))
                continue;

            var marker = data.markers[i];
            marker.setIcon(config.marker);
        }

        if (data.activeMarker) {
            data.activeMarker.setIcon(config.markerHover);
        }
    }

    function initMap() {
        if (!data.desktop)
            return;

        // Markers
        var bounds = new google.maps.LatLngBounds();

        for (var i in data.data) {
            if (isNaN(i))
                continue;

            var company = data.data[i];

            var latLng = new google.maps.LatLng(company.lat, company.lng);

            var marker = new google.maps.Marker({
                position: latLng,
                icon: data.selectedId == company.id ? config.markerHover : config.marker,
                title: company.name

            });

            data.data[i].marker = marker;

            google.maps.event.addListener(marker, 'click', (function(marker, company) {
                return function() {
                    data.selectedId = company.id;
                    data.activeMarker = marker;
                    urlParameters.id = company.id;


                    if (data.map.getZoom() < 10) {
                        data.map.setZoom(10);
                        data.map.setCenter(marker.getPosition());
                    }
                    updateGUI();
                    updateMarkers();
                    updateUrl();
                }
            })(marker, company));
            data.markers.push(marker);
            bounds.extend(marker.getPosition());
        }

        data.originalBound = bounds;

        data.map = new google.maps.Map(document.getElementById('gmaps'), {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            disableDefaultUI: true,
            scrollwheel: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            streetViewControl: false
        });

        // var input = $("#cd-branch-input");
        var input = document.getElementById("cd-branch-input-desktop");

        data.searchBox = new google.maps.places.SearchBox(input);
        // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        data.map.addListener('bounds_changed', function() {
            // prevent this from executing before the mal loaded correctly
            if (data.map.getCenter()) {
                urlParameters.lat = data.map.getCenter().lat();
                urlParameters.lng = data.map.getCenter().lng();
                urlParameters.z = data.map.getZoom();

                data.searchBox.setBounds(data.map.getBounds());

                if (data.updateTimeout) {
                    // clear old event
                    window.clearTimeout(data.updateTimeout);
                }

                // wait for the clusterer to do its work
                data.updateTimeout = window.setTimeout(function() {
                    if (data.activeMarker && !data.activeMarker.map) {
                        data.selectedId = null;
                        urlParameters.id = null;
                        data.activeMarker = null;
                        updateMarkers();
                    }

                    updateResultsBasedOnMap();
                    scrollToActiveCard();
                    updateUrl();

                    data.updateTimeout = null;
                }, 500);
            }
        });

        data.searchBox.addListener('places_changed', function() {
            searchDesktop();
        });

        // Create the DIV to hold the control and call the ZoomControl() constructor
        // passing in this DIV.
        var zoomControlDiv = document.createElement('div');
        var zoomControl = new ZoomControl(zoomControlDiv, data.map);

        zoomControlDiv.index = 1;
        data.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);

        updateAndClusterMarkers();
    }

    function mobileSearch(query) {
        $.getJSON("https://maps.googleapis.com/maps/api/geocode/json?address="+encodeURIComponent(query)+"&region=ch&country=ch&key=" + localGoogleKey, function(geoData) {
            if (geoData.status == "OK") {
                if (geoData.results.length >= 1) {
                    var result = geoData.results[0],
                        lat = result.geometry.location.lat,
                        lng = result.geometry.location.lng;

                    urlParameters.lat = lat;
                    urlParameters.lng = lng;
                    urlParameters.text = query;
                    urlParameters.z = 11;

                    sortMobileResults();
                    filterMobileResults();
                    updateUrl();
                }
            }
        });
    }

    function searchDesktop() {
        var places = data.searchBox.getPlaces();

        var bounds = new google.maps.LatLngBounds();
        places.forEach(function(place) {
            if (!place.geometry) {
                return;
            }

            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });

        data.map.fitBounds(bounds);

        if (data.map.getZoom() > 13) {
            data.map.setZoom(13);
        }

        urlParameters.text = $("#cd-branch-input-desktop").val();

        updateGUI();
        updateUrl();
    }

    function sortMobileResults() {
        var lat = urlParameters.lat,
            lng = urlParameters.lng,
            newCompanyData = [];

        if (lat && lng) {

            for (var i in data.data) {
                var company = data.data[i],
                    distance = 100000000;

                if (data.mode == "both" || data.mode == "drogerie" && company.drogerie || data.mode == "apotheke" && company.apotheke) {
                    // calculate the distance
                    distance = Math.round(Math.sqrt(Math.abs(lat - company.lat) + Math.abs(lng - company.lng)) * 10000);

                    newCompanyData[distance] = company;
                }
            }

            data.sortedCompanies = newCompanyData;
        }
    }

    function filterMobileResults() {
        var count = 0,
            $firstCard = $("#companyDetail"),
            $companyDetailContainer = $("#search-container-result");

        if (data.sortedCompanies.length > 0) {
            $("#search-container-result .added").remove();
            for (var i = 0; i < data.sortedCompanies.length; i++) {

                var company = data.sortedCompanies[i],
                    $newCard = $firstCard.clone();

                // if (company && count <= 19) {
                if (company) {

                    if (data.mode == "both" || data.mode == "drogerie" && company.drogerie || data.mode == "apotheke" && company.apotheke) {
                        count++;
                        // remove all old cards
                        fillDetailBlock($newCard, company);
                        $newCard.addClass("added");
                        $newCard.show();
                        $newCard.attr("id", "company-" + company.id);
                        $companyDetailContainer.append($newCard);
                    }
                }
                if (count > window.maxShownShops) {
                    break;
                }
            }
        }

        $("#cd-branch-input-mobile").blur();

        $firstCard.hide();
        // buildMobileCompanyList(newCompanyData);
    }

    /**
     * bind the events
     */
    function bindEvents() {
        $("#btn-back").on("click", function() {
            if (data.desktop) {
                data.map.fitBounds(data.originalBound);
                data.selectedId = null;
                showDetails();
                $("#cd-branch-input").val("");
                $("#cd-branch-input-desktop").val("");
            }
        });

        $("#cd-search-branch").on("submit", function() {
            if ( $(window).width() < 768 ) {
                var searchValue = $("#cd-branch-input-mobile").val();

                mobileSearch(searchValue);
            }
            else {
                google.maps.event.trigger(document.getElementById('cd-branch-input-desktop'), 'focus', {});
                google.maps.event.trigger(document.getElementById('cd-branch-input-desktop'), 'keydown', { keyCode: 13 });
            }
            return false;
        });

        $(".custom-radio input").on("click", function() {

            if ($("#radioAll").prop("checked")) {
                data.mode = "both";
            }
            if ($("#radioApotheken").prop("checked")) {
                data.mode = "apotheke";
            }
            if ($("#radioDrogerien").prop("checked")) {
                data.mode = "drogerie";
            }

            urlParameters.mode = data.mode;

            if (data.desktop) {
                updateAndClusterMarkers();
                updateGUI();
            }
            else {
                filterMobileResults();
            }

            updateUrl();
        });
    }

    return {
        init: function() {
            if ($("#gmaps").length > 0) {
                google.maps.event.addDomListener(window, 'load', function() {
                        window.setTimeout(init, 1);
                });
            }
        },
        initPlzBox: function() {
            initPlzBox();
        }
    };

})();

go4map.init();
go4map.initPlzBox();

function ZoomControl(controlDiv, map) {

    // Creating divs & styles for custom zoom control
    //controlDiv.style.padding = '5px';
    controlDiv.style.marginRight = '45px';
    controlDiv.style.marginBottom = '70px';

    // Set CSS for the control wrapper
    var controlWrapper = document.createElement('div');
    controlWrapper.style.backgroundColor = 'transparent';
    controlWrapper.style.borderStyle = 'none';
    controlWrapper.style.cursor = 'pointer';
    controlWrapper.style.width = '35px';
    controlWrapper.style.height = '70px';
    controlDiv.appendChild(controlWrapper);

    // Set CSS for the zoomIn
    var zoomInButton = document.createElement('div');
    zoomInButton.style.width = '40px';
    zoomInButton.style.height = '40px';
    zoomInButton.style.borderRadius = '20px';
    zoomInButton.style.marginBottom = '5px';
    zoomInButton.style.backgroundImage = 'url("/static/grethers-pastilles/img/map/zoomin.png")';
    zoomInButton.style.boxShadow = '0px 0px 5px 1px rgba(0, 0, 0, 0.3)';
    controlWrapper.appendChild(zoomInButton);

    // Set CSS for the zoomOut
    var zoomOutButton = document.createElement('div');
    zoomOutButton.style.width = '40px';
    zoomOutButton.style.height = '40px';
    zoomOutButton.style.borderRadius = '20px';
    zoomOutButton.style.marginBottom = '10px';
    zoomOutButton.style.backgroundImage = 'url("/static/grethers-pastilles/img/map/zoomout.png")';
    zoomOutButton.style.boxShadow = '0px 0px 5px 1px rgba(0, 0, 0, 0.3)';
    controlWrapper.appendChild(zoomOutButton);

    // Setup the click event listener - zoomIn
    google.maps.event.addDomListener(zoomInButton, 'click', function() {
        map.setZoom(map.getZoom() + 1);
    });

    // Setup the click event listener - zoomOut
    google.maps.event.addDomListener(zoomOutButton, 'click', function() {
        map.setZoom(map.getZoom() - 1);
    });

}