$(function() {

    // PDF download tracking
    $("[data-pdf-tracker]").on("click", function() {
        window.trackEvent('course-pdf', 'download', $(this).data('pdf-tracker'), 1);
    });

    if ($(".call-to-action").length) {
        $(document).on("scroll", function() {
            checkCallToActionBoxes();
        });
    }

    function checkCallToActionBoxes() {
        $(".call-to-action").each(function() {
            if (window.elementInViewport(this)) {
                if (!$(this).hasClass('tracked')) {
                  $(this).addClass('tracked');
                  window.trackEvent('call-to-action', 'show', $("head title").text(), 1);
                }
            }
        });
    }
});
