$(function () {
    setTimeout(function () {
        window.equalizeByElement($('.teasers-showcase .teaser .highlight-box .inner'));
    }, 150);

    if ($('.idle-screen section.page-blog-overview').length > 0) {
        /**
         * Blogübersicht Teaser Loading
         */
        if (!window.go4history.get("category")) {
          window.go4history.update("category", "all");
          window.trackEvent('blog', 'category', 'all', 1);
        } else {
          window.trackEvent('blog', 'category', window.go4history.get('category'), 1);
        }

        var $activeCategory = $('.page-blog-overview .categories li[data-category="' + window.go4history.get("category") + '"]');
        $activeCategory.addClass("active");
        $(".btn-load-more").data("total", $activeCategory.data("count"));

        $('.page-blog-overview .categories li').on('click', function () {
            if (!$(this).hasClass('active')) {
                updateTeasers($(this))
            }
        });

        window.go4history.onStateChange(function() {
          var $activeElem = $('.page-blog-overview .categories li[data-category="' + window.go4history.get("category") + '"]');
          updateTeasers($activeElem);
        });

        function updateTeasers($activeElem) {
          window.go4history.push("category", $activeElem.data("category"));
          window.trackEvent('blog', 'category', $activeElem.data("category"), 1);

          var $btnLoadMore = $(".btn-load-more");
          $btnLoadMore.data("offset", "0");
          $btnLoadMore.data("total", $activeElem.data("count"));

          loadTeasers(true, $activeElem);
        }

        $('.page-blog-overview .btn-load-more').on('click', function (e) {
            e.preventDefault();
            loadTeasers(false, $(this));
        });

        $('body.page-blog-overview').on('click', '.teaser a.caption', function (e) {
            e.preventDefault();
            $('.page-blog-overview .categories li[data-category="' + $(this).data('category') + '"]').click();
        });

        loadTeasers(false, null);
    }

    function loadTeasers(clearShowcase, clickTarget) {
        if (!$('body').hasClass('teasers-loading')) {
            // add "on-load" styles
            if(clearShowcase) {
                $('.page-blog-overview .categories li.active').removeClass('active');
                clickTarget.addClass('active');
            }
            $('body').addClass('teasers-loading');

            var $btnLoadMore = $(".idle-screen .btn-load-more");

            if ($btnLoadMore.length < 1) {
                $btnLoadMore = $(".active-screen .btn-load-more")
            }

            var $ctaWrapper = $('.cta-wrapper'),
                $teaserShowcase = $('.teasers-showcase'),
                offset = parseInt($btnLoadMore.data("offset")),
                limit = parseInt($btnLoadMore.data("limit")),
                total = parseInt($btnLoadMore.data("total"));

            if ((!clickTarget || !clickTarget.hasClass("btn-load-more")) && window.go4history.get("limit")) {
                limit = window.go4history.get("limit");
            }

            if ((!offset && offset != 0) || (!limit && limit != 0)) {
                $('body').removeClass('teasers-loading');
                return;
            }

            $.get("/blog/get-blog-pages/" + offset + "/" + limit + "/" + window.go4history.get("category"), function(response) {
                $btnLoadMore.data("offset", offset + limit);

                window.go4history.update("limit", parseInt(offset + limit));

                if (offset + limit >= total) {
                    $ctaWrapper.addClass('hidden');
                } else {
                    $ctaWrapper.removeClass('hidden');
                }

                if(clearShowcase) $teaserShowcase.empty();

                $teaserShowcase.append($(response));
                window.equalizeByElement($('.teasers-showcase .teaser .highlight-box .inner'));

                $('body').removeClass('teasers-loading');
            });
        }
    }

    if ($(".idle-screen section.page-blog-detail").length > 0) {
        addShareTags();
    }

    function addShareTags() {
        $("head").append('<meta property="og:title" content="' + window.shareTitle + '">')
            .append('<meta property="og:type" content="article">')
            .append('<meta property="og:description" content="' + window.shareDescription + '">')
            .append('<meta property="og:url" content="' + window.shareUrl + '">')
            .append('<meta property="og:image" content="' + window.shareImage + '">')

            .append('<meta property="article:section" content="' + window.shareTitle + '">')

            .append('<meta property="twitter:card" content="summary">')
            .append('<meta property="twitter:title" content="' + window.shareTitle + '">')
            .append('<meta property="twitter:description" content="' + window.shareDescription + '">')
            .append('<meta property="twitter:url" content="' + window.shareUrl + '">');
    }

    // category slider -> fix for tablets
    styleCategorySlider();
    function styleCategorySlider() {
        var parentWidth = $('section.page-blog-overview .categories-slider .categories').width();
        var childrenWidth = 0;
        $('section.page-blog-overview .categories-slider .categories').find('li').each(function () {
            childrenWidth += $(this).width();
            childrenWidth += parseInt($(this).css('padding-left').slice(0, -2));
            childrenWidth += parseInt($(this).css('padding-right').slice(0, -2));
        });

        if (childrenWidth > parentWidth) {
            $('section.page-blog-overview .categories-slider .categories').css('justify-content', 'flex-start');
        } else {
            $('section.page-blog-overview .categories-slider .categories').css('justify-content', 'center');
        }
    }

    $(window).on('resize', function () {
        if ($('section.page-blog-overview').length > 0) {
            styleCategorySlider();
        }
    });

    var categoryAnimationInterval;

    // animate category slider in mobile
    if ($(window).width() < 992) {
        var $parent = $('.categories.em-list');
        var parentWidth = $parent.width();

        var $categories = $parent.find('li');
        var categoriesWidth = 0;

        $.each($categories, function () {
            categoriesWidth += $(this).width();
            categoriesWidth += parseInt($(this).css('padding-left').slice(0, -2));
            categoriesWidth += parseInt($(this).css('padding-right').slice(0, -2));
        });

        if (categoriesWidth > parentWidth) {
            var direction = 'left';

            categoryAnimationInterval = setInterval(function () {

                if (direction === 'left') {

                    setTimeout(function () {
                        if ($parent.scrollRight() < 2) {
                            direction = 'right';
                        } else {
                            $parent.scrollLeft($parent.scrollLeft() + 1);
                        }
                    }, 1000)

                } else if (direction === 'right') {

                    setTimeout(function () {
                        if ($parent.scrollLeft() < 1) {
                            direction = 'left';

                        } else {
                            $parent.scrollLeft($parent.scrollLeft() - 1);
                        }
                    }, 1000)
                }

            }, 30)

        }
    }

    $('.categories.em-list').on('click touchstart', function () {
        clearInterval(categoryAnimationInterval);
    });
});


$.fn.extend({
    scrollRight: function (val) {
        if (val === undefined) {
            return this[0].scrollWidth - (this[0].scrollLeft + this[0].clientWidth) + 1;
        }
        return this.scrollLeft(this[0].scrollWidth - this[0].clientWidth - val);
    }
});
