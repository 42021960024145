$(function () {
    var navScroll = 0;

    /**
     * Nav on Resize Mobile to Desktop
     */
    $(window).on('resize', function () {
        if ($('body').hasClass('page-extranet') && $(this).width() >= 992 && $('#nav').hasClass('nav-open')) {
            $('#nav').removeClass('nav-open');
        }
    });

    if ($('body').hasClass('page-extranet')) {
        $('#nav').on('scroll', function () {
            if ($('#nav').hasClass('nav-open')) {
                navScroll = $('#nav').scrollTop();

                if (navScroll > 60 && !$('.mobile-toggle').hasClass('shadow')) {
                    $('.mobile-toggle').addClass('shadow');
                } else if (navScroll <= 60) {
                    $('.mobile-toggle').removeClass('shadow');
                }
            }
        });


        // show active nav, hide others
        $('.level-1-item:not(.profile-item):not(.active) .level-1-content').css('opacity', 0);
        $('.level-1-item.active .level-1-content').css('opacity', 1);
    }

    /**
     * Thesis Bbackend
     */
    $('.page-extranet-backend ul.tools-list li').on('click', function () {
        $('.page-extranet-backend ul.tools-list li.active').removeClass('active');
        $(this).addClass('active');
    });


    /**
     * Diplomarbeit Editmode - Summernote Editor
     */
    var summernoteEditors = $('.summernote-editor');
    if (summernoteEditors.length > 0) {
        summernoteEditors.each(function () {
            $(this).summernote({
                tabsize: 2,
                lang: 'de-DE',
                height: 120,
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'italic', 'underline', 'clear']],
                    ['para', ['ul', 'ol', 'link']]
                ],
                styleTags: ['h2', 'h3', 'h4', 'p'],
                disableDragAndDrop: true
            });
        });
    }

    $('.note-editing-area').each(function () {
        $(this).prepend($('<label>' + $(this).parents(".summernote-editor-wrapper").data("label") + '</label>'));
    });

    $('html').on('focus', '.note-editing-area .note-editable', function () {
        $(this).parents('.summernote-editor-wrapper').find('label').addClass('animate-self');
    });

    $('html').on('focusout', '.note-editing-area .note-editable', function () {
        if ($(this).text() === "") {
            $(this).parents('.summernote-editor-wrapper').find('label').removeClass('animate-self');
        }
    });

    /**
     * Clipboard Tooltip
     */
    $('#InputShortURL').on('click', function () {
        var _this = $(this),
            $inputWithButton = _this.closest(".input-with-button"),
            copyInput = document.getElementById("InputShortURL");

        copyInput.select();
        copyInput.setSelectionRange(0, 99999);
        document.execCommand('copy');
        $inputWithButton.addClass('show-tooltip');
        $inputWithButton.addClass('active');

        setTimeout(function () {
            $inputWithButton.removeClass('show-tooltip');
            $inputWithButton.removeClass('active');
        }, 1200);
    });

    $(document).on('click', '.publish-wrapper', function (e) {
        var target = $(e.originalEvent.target);
        if (target.hasClass('publish') || target.hasClass('label') || target.hasClass('publish-wrapper')) {
            $(this).toggleClass('show-dropdown');
        }
    });

    $('html').on('change', '.summernote-editor-wrapper .form-check input', function () {
        var $label = $(this).closest('label');

        if ($(this).prop('checked')) {
            $label.addClass('checked')
        } else {
            $label.removeClass('checked')
        }
    });

    setTimeout(function () {
        toggleSummernoteModalCheckbox();
    }, 500)
});

function toggleSummernoteModalCheckbox() {
    $('.summernote-editor-wrapper .form-check input').each(function () {
        if ($(this).prop('checked')) {
            $(this).closest('label').addClass('checked');
        }
    });
}