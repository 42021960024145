/**
 * Created by andimoser on 30.07.18.
 */

var go4history = (function() {
    var self = this,
        data = {},
        listeners = [];

    function updateHistory(push) {
        var hash = '';

        for (var key in data) {
            if (data[key]) {
                hash += key + '=' + data[key] + '&';
            }
        }

        if (push) {
            history.pushState(undefined, undefined, "#" + hash);
        }
        else {
            history.replaceState(undefined, undefined, "#" + hash);
        }
    }

    function updateData() {
        var hash = location.hash.replace(/^.*?#/, '');
        var pairs = hash.split('&');

        data = {};
        $.each(pairs, function( index, value ) {

            var key = value.split('=')[0];
            var keyValue = value.split('=')[1];

            if(key !== '' && keyValue !== '') {
                data[key] = keyValue;
            }
        });
    }

    function fireEvents() {
        $.each(listeners, function( index, callback ) {
            if (callback && typeof callback === 'function') {
                callback(data);
            }
        });
    }

    window.onpopstate = function() {
        updateData();

        fireEvents();
    };

    return {
        get: function(name) {
            updateData();
            return data[name];
        },
        pushValues: function(values, fireEvents) {
            var historyUpdate = false;

            $.each(values, function(key, value) {
                if (data[key] != value) {
                    data[key] = value;

                    historyUpdate = true;
                }
            });

            if (historyUpdate) {
                updateHistory(true);
            }

            if (fireEvents || !!fireEvents)
                fireEvents();
        },
        push: function(name, value, fireEvents) {
            if (data[name] != value) {
                data[name] = value;

                updateHistory(true);
            }

            if (fireEvents || !!fireEvents)
                fireEvents();
        },
        updateValues: function(values, fireEvents) {
            var historyUpdate = false;

            $.each(values, function(key, value) {
                if (data[key] != value) {
                    data[key] = value;

                    historyUpdate = true;
                }
            });

            if (historyUpdate) {
                updateHistory(false);
            }

            if (fireEvents || !!fireEvents)
                fireEvents();
        },
        update: function(name, value, fireEvents) {
            if (data[name] != value) {
                data[name] = value;

                updateHistory(false);
            }

            if (fireEvents || !!fireEvents)
                fireEvents();
        },
        getAll: function() {
            updateData();
            return data;
        },
        onStateChange: function(callable) {
            listeners.push(callable);
        },
        popstate: function() {
            updateData();
            fireEvents();
        }
    }
})();

window.go4history = go4history;
