$(function () {
  var isEditmode = ($("body.editmode").length > 0);
  var customEvent = document.createEvent("Event");

  customEvent.initEvent("selectChanged", true, true);
  // toggle select-dropdown
  $('html').on('click', '.teko-forms form .teko-input:not(.vue-input) .select', function () {

    if (!$(this).hasClass('open')) {
      $('.teko-forms form .teko-input .select.open').removeClass('open');
      $('.teko-input.add-select-index').removeClass('add-select-index');
    }

    $(this).toggleClass('open');
    $(this).parents('.teko-input').toggleClass('add-select-index');
  });

  // handle new option click
  $('html').on('click', '.teko-forms form .teko-input:not(.vue-input) .select .option:not(.display-option)', function () {
    var _thisParent = $(this).parents('.teko-input');

    _thisParent.find('.option.selected').removeClass('selected');
    $(this).addClass('selected');

    // put select content into display option
    _thisParent.find('.option.display-option').html($(this).html());
    _thisParent.find('.option.display-option').data('value', $(this).data('value'));
    _thisParent.find('.display-option').removeClass('empty');
    if (!_thisParent.hasClass('filled'))
      _thisParent.addClass('select-filled');

    // update hidden select
    _thisParent.find('select').val($(this).data('value')).get(0).dispatchEvent(customEvent);

    // for multi-level selects -> show children
    var childFieldData = $(this).data('child-name');

    if (childFieldData && childFieldData !== "") {
      // check if this child is already active
      if ($('.teko-input:not(.hidden-child) .select[data-child-name="' + childFieldData + '"]').length <= 0) {
        // hide/destroy previously opened from this group
        var preOpenedField = $('.teko-input:not(.hidden-child)[data-child-field-group="' + $(this).data('child-field-group') + '"]');

        if (preOpenedField.length > 0) {
          preOpenedField.addClass('hidden-child');
          preOpenedField.find('select').remove();
        }

        // open new
        var newActive = $('.select[data-child-name="' + childFieldData + '"]').parents('.teko-input');
        newActive.removeClass('hidden-child');

        // generate new hidden select
        newActive.append($(generateHiddenSelectTagFromDummy(newActive.find('.select'))));

        // check if child-value has already been selected and update select
        newActive.find('select').val(newActive.find('.display-option').data('value'));
      }
    }
  });

  // close select-pulldown if clicked outside
  $(document).bind('click', function (e) {
    if (e.originalEvent) {
      var target = $(e.originalEvent.target);

      if (!target.hasClass('option')) {
        $('.teko-forms form .teko-input:not(.vue-input) .select').removeClass('open');
        $('.teko-input:not(.vue-input).add-select-index').removeClass('add-select-index');
      }
    }
  });

  // fields focus
  $('html').on('focus', '.teko-forms form .teko-input:not(.vue-input) input', function () {
    if (!$(this).parent().hasClass('checkbox') && !$(this).parent().hasClass('radio')) {
      $(this).addClass('animate-label');
    }
  });
  $('html').on('focus', '.teko-forms form .teko-input:not(.vue-input) textarea', function () {
    if (!$(this).parent().hasClass('checkbox') && !$(this).parent().hasClass('radio')) {
      $(this).addClass('animate-label');
    }
  });

  // fields focusout
  $('html').on('focusout', '.teko-forms form .teko-input:not(.vue-input) input', function () {
    if ($(this).val() === "")
      $(this).removeClass('animate-label');
  });
  $('html').on('focusout', '.teko-forms form .teko-input:not(.vue-input) textarea', function () {
    if ($(this).val() === "")
      $(this).removeClass('animate-label');
  });

  window.initSelectDropdowns();
  /**
   * Submit events
   */
  $("form:not('.follow-up-form')").each(function () {
    var $form = $(this),
      $submitButton = ($form.find(".teko-input.submit").length) ? $form.find(".teko-input.submit") : $form.find(".btn-primary.submit"),
      $requiredInputs = $form.find('input[required]:not([type=checkbox]), textarea[required]'),
      $requiredPhone = $form.find('[data-validate-phone] input[type=tel]'),
      $requiredCheckboxes = $form.find('input[type=checkbox][required]'),
      $customSelects = $form.find('select[required]'),
      $requiredJobFiles = $form.find('input.job-file[type=file]'),
      $overlay = $form.closest(".overlay"),
      disabledClass = 'disabled',
      isDynamicForm = $form.hasClass('dynamic-form'),
      formIsValid = false;

    let updateValidity = function () {
      if (isEditmode) return;

      let valid = true;

      $requiredPhone.each((i, field) => {
        if (!field.required && field.value.trim() === "") {
          return true;
        }

        const iti = window.intlTelInputGlobals.getInstance(field);
        valid = (iti && iti.telInput && iti.isValidNumber());
      });

      $requiredInputs.each(function () {
        if (!window.validateElement(this)) {
          valid = false;
        }
      });
      $requiredCheckboxes.each(function () {
        if (!$(this).prop('checked')) {
          valid = false;
        }
      });
      $customSelects.each(function () {
        if (!$(this).val()) {
          valid = false;
        }
      });

      $requiredJobFiles.each(function () {
        if (!window.validateElement(this)) {
          valid = false;
        }
      });

      if (!$submitButton.hasClass('teko-input')) {
        disabledClass = 'btn-disabled';
      }

      if (valid) {
        $submitButton.removeClass(disabledClass);
        formIsValid = true;
      } else {
        $submitButton.addClass(disabledClass);
        formIsValid = false;
      }
    };

    updateValidity();

    $requiredPhone.on("countrychange", updateValidity);

    $requiredInputs.on("paste", function () {
      // short delay, so it gets checked after the input is filled
      setTimeout(updateValidity, 100);
    });
    $requiredPhone.on("paste", function () {
      // short delay, so it gets checked after the input is filled
      setTimeout(updateValidity, 100);
    });
    $requiredCheckboxes.on("paste", function () {
      // short delay, so it gets checked after the input is filled
      setTimeout(updateValidity, 100);
    });
    $requiredInputs.on("keyup click", updateValidity);
    $requiredPhone.on("keyup click", updateValidity);
    $requiredCheckboxes.on("change", updateValidity);
    $customSelects.on("change selectChanged", function () {
      updateValidity();

      // update custom selects after short timeout,
      // because they are dynamically added after updating another select in some cases
      setTimeout(function () {
        $customSelects = $form.find('select').filter('[required]');
        $customSelects.on("change selectChanged", updateValidity);
        updateValidity();
      }, 100);
    });

    $requiredInputs.on("change", function () {
      // special validation for email field
      if ($(this).attr("type") == "email") {
        if (!window.validateElement(this)) {
          // double validation also in valideElement for form submit disabled/enabled
          if($(this).hasClass("email-validation-private-email") !== undefined && $(this).val().endsWith('@edu.teko.ch')) {
            $(this).parent().addClass('validation-error email-invalid-not-private');
          } else {
            $(this).parent().addClass('validation-error email-invalid');
          }
        } else {
          $(this).parent().removeClass('validation-error email-invalid email-invalid-not-private');
        }
      }
    });

    $requiredJobFiles.on('change', function () {
      updateValidity();
    });

    $form.on("submit", function (e) {
      e.preventDefault();

      if (isEditmode) return;
      if (!formIsValid) return;

      $form.addClass("loading");
      overlayloading = true;

      var data = {},
        action = $(this).attr("action");

      $(this).find("select, input, textarea").each(function () {
        if ($(this).is("[type=checkbox]")) {
          data[$(this).attr("name")] = $(this).is(":checked");
        } else {
          data[$(this).attr("name")] = $(this).val();
        }
      });


      // add recaptcha result token
      if ($form.children('#recaptchaResponse').length) {
        grecaptcha.execute('6LcwdGwiAAAAAGzH1jKlzqgXMS1i1n93_AFtnpLy', {action: 'submit'}).then(function (token) {
          $form.children('#recaptchaResponse').val(token);

          data = getFullSerializedData($form);

          postFormOnSubmit(action, data, isDynamicForm, $form, $overlay);
        });
      } else {
        data = getFullSerializedData($form);

        postFormOnSubmit(action, data, isDynamicForm, $form, $overlay);
      }

    });

    $overlay.on('overlay-opened', function () {
      if ($overlay.hasClass('inbound-style')) {
        window.trackEvent('call-to-action', 'click', $("head title").text(), 1);
      }

      window.trackEvent('form-' + $overlay.data('overlay-name'), 'show', window.shortTitle, 1);
    });
  });

  $(window).on("resize", setPaddingForCommentLabel);
  let $comment = $('#reg_bemerkung');
  if ($comment.length) {
    $comment.focus(function () {
      setTimeout(function () {
        setPaddingForCommentLabel();
      }, 200);
    });
  }
});

// data including files for job form
function getFullSerializedData($form) {
  let data = $form.serialize();

  if ($form.data('form-type') === 'job') {
    let formData = new FormData();
    $.each(data.split('&'), function (index, item) {
      let pair = item.split('=');
      formData.append(decodeURIComponent(pair[0]), decodeURIComponent(pair[1]));
    });

    let file = $form.find('input[name=file]')[0].files[0];
    formData.append('file', file);
    formData.append('location', $form.closest('.overlay').find('.job-location').text());
    formData.append('job', $form.closest('.overlay').find('.job-title').text());
    formData.append('jobLink', window.location.hostname + window.location.pathname + '#job=' + window.jobID + '&');

    data = formData;
  }

  return data;
}

function postFormOnSubmit(action, data, isDynamicForm, $form, $overlay) {
  if (typeof data === 'object') {
    postFormWithFiles(action, data, $form, $overlay);
    return false;
  }

  $.post(action, data, function (response) {
    if (isDynamicForm) {
      window.trackEvent('form-dynamic', 'submit', window.shortTitle, 1);

      if (response.success) {
        $("#msg-count").hide();
        $form.hide();
        $("#msg-success").show();
        $("#msg-error").hide();


        $('body,html').animate({
          scrollTop: $(".dynamic-form-wrapper").offset().top
        }, 1000);
      } else {
        if (response.duplicate) {
          $(".email-error-already-registered").show();
        } else {
          $("#msg-error").html(response.message).show();
        }
        $form.removeClass("loading");

        $('body,html').animate({
            scrollTop: $("#form-title").offset().top
          }, 1000
        );
      }
    } else {
      // only track if we have an overlay name. the registration form of the courseportal doesn't have this.
      if ($overlay.data('overlay-name')) {
        window.trackEvent('form-' + $overlay.data('overlay-name'), 'submit', window.shortTitle, 1);
      }

      if (response.inbound) {
        if (response.inboundSuccess) {
          var $inboundOverlay = $('.overlay.open[data-overlay-name^="overlay-inbound-"]'),
            $downloadOverlay = $('.overlay[data-overlay-name="download"]');

          $downloadOverlay.find("a.download-button").attr("href", response.downloadUrl);

          overlayloading = false;

          window.closeOverlay($inboundOverlay, true, false);
          window.openOverlayByName('download');
        } else {
          $overlay.find(".content-wrapper").hide();
          $overlay.find(".inbound-inner").append('<div class="content-wrapper response-text">' + response.html + '</div>');
        }

        resetForm($form);
        window.trackEvent('call-to-action', 'submit', window.shortTitle, 1);
      } else {
        // special case for info events if person is already registered to that info event
        if (response.infoEventAlreadyRegistered) {
          $form.find("input#email2").parent().addClass('validation-error email-invalid already-registered');
          $form.removeClass("loading");
        }

        if (response.success) {
          if (response.url) {
            window.location.replace(response.url);
          }
          if (response.html) {
            if (response.keepformvisible) {
              var errorElement = $form.parents(".box-inner").find(".error");
              errorElement.html(response.html);
              errorElement.css("display", "block");
            } else {
              $form.parents(".overlay").removeClass("has-prev");
              window.preClosedOverlays.pop();

              $form.parents(".box-content").hide();
              $form.parents(".box-inner").append('<div class="box-content response-text">' + response.html + '</div>');
            }
            resetForm($form);
          }
        } else {
          $form.parent().find("p.error").show();
          $form.removeClass("loading");
        }
      }

      overlayloading = false;
    }
  });

  return false;
}

function postFormWithFiles(action, data, $form, $overlay) {
  $.ajax({
    url: action,
    type: 'POST',
    data: data,
    contentType: false,
    processData: false,
    success: function (response) {
      if (response.success) {
        $form.closest('.overlay').find('.teko-forms').css('display', 'none');
        $form.closest('.overlay').find('.form-success').css('display', 'block');
      } else {
        $form.closest('.overlay').find('.teko-forms').css('display', 'none');
        $form.closest('.overlay').find('.form-error').css('display', 'block');
      }

      $form.removeClass('loading');
      clearForm($form);
      overlayloading = false;
    },
    error: function (xhr, status, error) {
      $form.closest('.overlay').find('.teko-forms').css('display', 'none');
      $form.closest('.overlay').find('.form-error').css('display', 'block');
      $form.removeClass('loading');
      clearForm($form);
      overlayloading = false;
    }
  });
};

function clearForm($form) {
  $form.removeClass('loading');
  $form[0].reset();
  $form.find('input').removeClass('animate-label');
  $form.find('[data-field-name=anrede] .display-option').text('');
  $form.find('[data-field-name=anrede]').closest('.teko-input').removeClass('select-filled');
}

function resetForm($form) {
  $form.removeClass("loading");
  $form.trigger("reset");
  $form.find('.teko-input input, .teko-input textarea').trigger('focusout');
  $form.find('.select-filled').removeClass('select-filled');
  $form.find('.display-option').addClass('empty');
  $form.find('.display-option').html('');
  $form.find('.teko-input.submit').addClass('disabled');
  $form.find(".btn-primary.submit").addClass('btn-disabled');
  $form.find('.option.selected').removeClass('selected');
}

$(window).on('load', function () {
  //var phones = [{ "mask": "(###) ###-##-##"}, { "mask": "(###) ###-##-############"}];
  var phones = [{"mask": "### ### ## ##"}];
  $('.teko-input.input-tel input').inputmask({
    mask: phones,
    greedy: false,
    definitions: {'#': {validator: "[0-9]", cardinality: 1}}
  });
});

/**
 * Validates an element. This does not check if the element is required or not
 * @param elem
 */
window.validateElement = function (elem) {

  var $elem = $(elem);

  if ($elem.hasClass("pw-check") && $elem.val() != "") {
    var pwScore = zxcvbn($elem.val()).score;

    $("#password-strength-meter").val(pwScore);

    if (pwScore < 3) {
      $elem.parent().find(".error").addClass("show");
      return false;
    } else {
      $elem.parent().find(".error").removeClass("show");
      return true;
    }
  }
  if ($elem.hasClass("pw-check-repeat") && $elem.val() != "") {
    if ($elem.val() == $(".pw-check").val()) {
      $elem.parent().find(".error").removeClass("show");
      return true;
    }
    $elem.parent().find(".error").addClass("show");
    return false;
  }

  if ($elem.hasClass("email-validation-private-email") && $elem.val() != "" && $elem.val().endsWith('@edu.teko.ch'))
    return false;

  // specific fields validation
  let specialValidation = $elem.data('validate-like');
  switch (specialValidation) {

    case 'socialsecuritynumber':
      let ahv13validator = new window.AHV13();
      if ($elem.val() !== '' && !ahv13validator.isValid($elem.val()) || $elem.val() === '' && $elem.prop('required'))
        return false;

      break;

    case 'mobile':
      let isPhoneValid = /^\+[\d\s]{13,19}$/.test($elem.val());
      if ($elem.val() !== '' && !isPhoneValid || $elem.val() === '' && $elem.prop('required'))
        return false;
      break;

    case 'email':
      let isEmailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test($elem.val());
      if ($elem.val() !== '' && !isEmailValid || $elem.val() === '' && $elem.prop('required'))
        return false;

      // also check for an existing TLD
      if ($elem.prop('required') && !window.TLDList.includes($elem.val().split('.').at(-1)))
        return false;

      break;

    case 'date':
      let userDate = $elem.val();

      if (parseInt(userDate.split('.')[2]) > (new Date().getFullYear() - 14))
        return false;

      let isDateValid = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|2)\d{3})\s*$/g.test(userDate) || /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19)\d{2})\s*$/g.test(userDate);
      if (!$elem.val() !== '' && !isDateValid || $elem.val() === '' && $elem.prop('required'))
        return false;

      break;

    case 'job-file':
      let showError = false;
      let isValid = true;
      let file = $elem[0].files[0];
      if ($elem.prop('required') && !file) {
        isValid = false;
      } else if (file) {
        if (file.size / 1024 / 1024 > 5 || $elem.val().split('.').pop().toUpperCase() !== 'PDF') {
          showError = true;
          isValid = false;
        }
      }

      if (showError && $elem.val()) {
        $elem.closest('.teko-input').addClass('validation-error');
      } else {
        $elem.closest('.teko-input').removeClass('validation-error');
      }

      if (file) {
        let xBtn = '<div class="btn-remove-file"></div>'
        $elem.find('~ .file-name').html(xBtn + $elem[0].files[0]['name']);
      } else {
        $elem.find('~ .file-name').text('');
      }

      if (showError || !isValid) {
        return false;
      }

      break;
  }

  return elem.validity.valid;
}

function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

function animateFormOnLoad(originalEvent, form) {
  originalEvent.preventDefault();

  form.addClass('loading');

  // simulate loading time
  setTimeout(function () {
    form.removeClass('loading');


    // check parent overlay type
    // -> simple
    var parentOverlay = form.parents('.overlay .box-content .inbound-inner');
    // ->inbound
    parentOverlay = (parentOverlay.length < 1) ? form.parents('.overlay .box-content') : parentOverlay;

    if (parentOverlay.length > 0) {
      parentOverlay.children().each(function () {
        if ($(this).hasClass('overlay-controls')) {
          return true;
        }
        $(this).remove();
      });

      var confirmationHTML =
        "<h2>Bestätigung</h2>" +
        "<span class='sub-title'>Sie haben sich erfolgreich angemeldet!</span>" +
        "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>";

      parentOverlay.append($(confirmationHTML));
    }
  }, 3500);
}

/**
 * Generates Select-Tags, two options:
 *
 * Initial select-HTML is a dummy. Generates hidden <select> and submits changes into it on dummy-select change
 */

window.initSelectDropdowns = function () {
  // do not render for hidden and already generated
  var dummySelects = $('.teko-forms form .teko-input:not(.hidden-child):not(.vue-input) .select:not(.is-generated)');
  dummySelects.each(function () {
    var hiddenSelect = generateHiddenSelectTagFromDummy($(this));
    $(this).parent().append(hiddenSelect);
  });
}

/**
 * Returns HTML as plain Text
 */
function generateHiddenSelectTagFromDummy(dummySelect) {
  var required = dummySelect.is('[required]') ? "required" : "",
    html = '<select name="' + dummySelect.data('field-name') + '" ' + required + ' style="display: none;">';

  dummySelect.find('.option').each(function () {
    var selected = '';
    if ($(this).data('selected') == true) {
      selected = 'selected';
    }

    html += '<option value="' + $(this).data('value') + '" ' + selected + '>' + $(this).text() + '</option>';
  });

  html += '</select>';

  dummySelect.addClass('is-generated');

  return html;
}

function setPaddingForCommentLabel() {
  let $courseForm = $('div[data-overlay-name="course-form"]');

  if ($courseForm.length) {
    let $comment = $('#reg_bemerkung'),
      $label = $('label[for="reg_bemerkung"]');

    if ($comment.length && $label.length) {
      let labelHeight = $label.height();

      // add some margin
      labelHeight += 5;
      $comment.attr('style', 'padding-top: ' + labelHeight + 'px !important');
    }
  }
}

window.switchLoginBox = function () {
  $('.overlay.login .box-inner').toggleClass('active');
  $('.overlay.login .close-btn').toggleClass('on-blue');
}

window.showReCaptchaLabel = function () {
  $('.grecaptcha-badge').addClass('show-label');
}

window.hideReCaptchaLabel = function () {
  $('.grecaptcha-badge').removeClass('show-label');
}
