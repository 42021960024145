import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils';

export default {
  selector: '[data-validate-phone]',
  isValid: false,

  config: {
    nationalMode: false,
    autoInsertDialCode: true,
    separateDialCode: false,
    preferredCountries: [
      'ch',
      'de',
      'fr',
      'it',
      'at'],
    localizedCountries: {
      fr: "Frankreich",
      de: "Deutschland",
      es: "Spanien",
      it: "Italien",
      ch: "Schweiz",
      nl: "Niederlande",
      at: "Österreich",
      dk: "Dänemark",
    },
    utilsScript: utils,
  },

  mounted() {
    document.querySelectorAll(this.selector).forEach(container => {
      const field = container.querySelector('input');
      if (field && !field.classList.contains('ini')) {
        const iti = intlTelInput(field, this.config);
        const data = this.methods.loadData(container);

        if (data['country'] && !iti.isValidNumber()) {
          iti.setCountry(data['country']);
        }
        if (!iti.isValidNumber() && !field.required && field.value.length < 4) {
          field.value = '';
        }

        field.addEventListener('keyup', e => this.methods.validateNumber(e, container, field, iti));
        field.addEventListener('blur', e => this.methods.toggleMessage(e, container, field));
        field.addEventListener('countrychange', e => this.methods.validateNumber(e, container, field, iti));
        field.addEventListener('paste', (e) => {
          setTimeout(() => {
            // add short timeout because else the value is not inserted yet
            this.methods.validateNumber(e, container, field, iti)
          }, 100);
        });

        field.classList.add('ini')
      }
    });
  },

  methods: {
    validateNumber(e, container, field, iti) {
      this.isValid = iti.isValidNumber();

      let value = field.value;
      const keysToIgnore = [
        'ArrowRight',
        'ArrowLeft',
        'Delete',
        'Backspace'
      ];
      if (value !== '' && !keysToIgnore.includes(e.key)) {
        if (value.charAt(0) !== '+') {
          value = '+' + value;
        }

        if (value.charAt(1) === '0') {
          value = value.substring(0, 1) + value.substring(2);
        }

        value = value.replace(/[^0-9.,+\s]|(?!^)\s\s/g, '').replace(/\s+/g, ' ');
        iti.setNumber(value);
      }

      container.classList.toggle("validation-error", !this.isValid);
    },

    toggleMessage(e, container, field) {
      let message = container.querySelector('.error');
      if (message) {
        container.querySelector('.error').classList.toggle('show',
          e.type === 'blur' &&
          field.value.length > 0 &&
          !this.isValid || !this.isValid && field.required
        );
      }
    },

    loadData(field) {
      const dataAttributeValue = field.getAttribute('data-validate-phone');
      const keyValuePairs = dataAttributeValue.split(',');
      const data = {};
      if (dataAttributeValue) {
        keyValuePairs.forEach(pair => {
          const [key, value] = pair.split(':');
          data[key.trim()] = value.trim();
        });
      }
      return data;
    }
  }
};
