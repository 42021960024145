window.Sliders = function() {
  this.swipersStash = [];
  this.thesisDetailImageSlider = []; // thesis gallery, default on page
  this.thesisDetailImageModalSlider = []; // thesis gallery, in modal window
}

window.Sliders.prototype.reInitSliders = function () {
  let sliders = this;

  // destroy existing
  sliders.destroyAll();

  // init new
  sliders.initThesisDetailImageSlider();
  sliders.initNewsTeaserSlider();
};

window.Sliders.prototype.destroyAll = function () {
  let sliders = this;

  if (sliders.swipersStash.length > 0) {
    $(sliders.swipersStash).each(function () {
      try {
        this.destroy(true, true);
      } catch (e) {
        // ignore
      }
    });
    sliders.swipersStash = [];
  }
};

window.Sliders.prototype.initNewsTeaserSlider = function () {
  if ($('body.editmode').length) return false;

  let sliders = this;
  let spaceBetweenValue = $(window).width() < 768 ? 15 : 20; // Adjust the breakpoint value as needed
  let $newsTeaserSwipers = $('.b-news-teaser-slider .swiper-container');

  $newsTeaserSwipers.each((i, obj) => {
    let $this = $(obj);
    let $btnPrev = $this.closest('.b-news-teaser-slider').find('.swiper-button-prev.swiper-button');
    let $btnNext = $this.closest('.b-news-teaser-slider').find('.swiper-button-next.swiper-button');

    let newsTeaserSwiper = new Swiper($this, {
      slidesPerView: 3,
      slidesPerGroup: 3,
      loop: false,
      spaceBetween: spaceBetweenValue,
      navigation: {
        prevEl: $btnPrev,
        nextEl: $btnNext,
      },
      breakpoints: {
        990: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        500: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      },
      on: {
        init: () => {
          $btnPrev.addClass('swiper-button-disabled');
        },
        fromEdge: () => {
          $btnPrev.removeClass('swiper-button-disabled');
          $btnNext.removeClass('swiper-button-disabled');
        },
        reachBeginning: () => {
          $btnPrev.addClass('swiper-button-disabled');
        },
        reachEnd: () => {
          $btnNext.addClass('swiper-button-disabled');
        }
      }
    });

    sliders.swipersStash.push(newsTeaserSwiper);
  });
};

window.Sliders.prototype.initThesisDetailImageSlider = function () {
  let sliders = this;
  let spaceBetween = $(window).width() >= 1024 ? 48 : 14;

  let $thumbsEm = $('.page-thesis-detail .b-thesis-detail-image-modal-slider .swiper-container.thumbnails');
  let thumbsSwiper = new Swiper($thumbsEm, {
    spaceBetween: 8,
    slidesPerView: 6,
    threshold: 30
  });

  let $modalEm = $('.page-thesis-detail .b-thesis-detail-image-modal-slider .swiper-container.modal');
  let modalSwiper = new Swiper($modalEm, {
    navigation: {
      prevEl: '.arrow.modal.prev',
      nextEl: '.arrow.modal.next',
    },
    thumbs: {
      swiper: thumbsSwiper
    },
    slidesPerView: 1,
    spaceBetween: 48,
    on: {
      slideChange: function () {
        if ($modalEm.closest('.b-thesis-detail-image-modal-slider.show').length && sliders.thesisDetailImageSlider && sliders.thesisDetailImageModalSlider) {
          sliders.thesisDetailImageSlider.slideTo(sliders.thesisDetailImageModalSlider.activeIndex);
        }
      }
    }
  });

  let $mainEm = $('.page-thesis-detail .b-thesis-detail-image-slider .swiper-container');
  let mainSwiper = new Swiper($mainEm, {
    navigation: {
      prevEl: '.arrow.main.prev',
      nextEl: '.arrow.main.next',
    },
    slidesPerView: 1,
    spaceBetween: spaceBetween,
    autoHeight: true,
    /*
    thumbs: {
        swiper: modalSwiper
    },
     */
    on: {
      slideChange: function () {
        mainSwiper.updateAutoHeight(400);

        if (sliders.thesisDetailImageModalSlider && sliders.thesisThumbsSwiper && sliders.thesisDetailImageSlider) {
          sliders.thesisThumbsSwiper.slideTo(sliders.thesisDetailImageSlider.activeIndex);
          sliders.thesisDetailImageModalSlider.slideTo(sliders.thesisDetailImageSlider.activeIndex);
        }
      }
    }
  });

  sliders.swipersStash.push(mainSwiper);
  sliders.thesisDetailImageSlider = mainSwiper;
  sliders.swipersStash.push(modalSwiper);
  sliders.thesisDetailImageModalSlider = modalSwiper;
  sliders.swipersStash.push(thumbsSwiper);
  sliders.thesisThumbsSwiper = thumbsSwiper;

};

window.Sliders.prototype.initThesisDetailImageModalSlider = function () {
};

window.Sliders.prototype.toggleThesisGalleryModal = function () {
  $('body').toggleClass('overlay-open');
  $('.b-thesis-detail-image-modal-slider').toggleClass('show');

  setTimeout(function () {
    window.Sliders.prototype.pauseVideos($('.b-thesis-detail-image-modal-slider'));
    window.Sliders.prototype.pauseVideos($('.b-thesis-detail-image-slider'));
  }, 300);
};

window.Sliders.prototype.pauseVideos = function ($parent) {
  let $video = $parent.find('video');
  if ($video.length) {
    $video.each(function () {
      $(this).get(0).pause();
    });
  }

  let $iframe = $parent.find('iframe');
  if ($iframe.length && $iframe.attr("src").indexOf("youtube") !== -1) {
    $iframe.each(function () {
      let $this = $(this);
      let $src = $this.attr("src").replace('?autoplay=1', '');
      $this.attr("src", $src + "?autoplay=0");
    });
  }
};

$(document).keyup(function (e) {
  if (e.key === "Escape" && $('.b-thesis-detail-image-modal-slider.show').length) {
    $('body').removeClass('overlay-open');

    let $parent = $('.b-thesis-detail-image-modal-slider');
    $parent.removeClass('show');

    setTimeout(function () {
      Sliders.prototype.pauseVideos($parent);
    }, 300);
  }
});

$(function () {
  $('.b-thesis-detail-image-slider .btn-play-video')
    .add('.b-thesis-detail-image-modal-slider .btn-play-video')
    .on('click', function () {
      let $parent = $(this).closest('.img');
      let $iframe = $parent.find('iframe');

      if (!$parent.hasClass('video-playing')) {
        if ($iframe.length && $iframe.attr("src").indexOf("youtube") !== -1) {
          $iframe.attr("allow", "autoplay");

          let $src = $iframe.attr("src").replace('?autoplay=0', '');
          $iframe.attr("src", $src + "?autoplay=1");
          $parent.addClass('video-playing');
        }
      } else {
        if ($iframe.length && $iframe.attr("src").indexOf("youtube") !== -1) {
          let $src = $iframe.attr("src").replace('?autoplay=1', '');
          $iframe.attr("src", $src + "?autoplay=0");
        }
        $parent.removeClass('video-playing');
      }
    });

  $('html').on('keydown keyup paste', '.b-news-teaser-slider .news-box .pimcore_tag_textarea', function () {
    let $this = $(this);
    let maxChars = 100;

    let $counter = $this.closest('.news-box').find('.char-counter');
    let $currentCount = $counter.find('.current');
    let text = $this.text();
    let currentLength = text.length;

    $currentCount.text(currentLength);

    if (maxChars < text.length) {
      text = text.substr(0, maxChars);
      $this.text(text);
      $currentCount.text(maxChars);
      placeCaretAtEnd($currentCount.get(0));
    }
  });
});

function placeCaretAtEnd(el) {
  el.focus();
  if (typeof window.getSelection != "undefined"
    && typeof document.createRange != "undefined") {
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != "undefined") {
    var textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
}
