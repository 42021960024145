window.clearImageInterval = null;

$(function () {
  let $croppieElement;
  let $step;
  const $form = $("form.follow-up-form");

  if ($form.length) {
    let $requiredInputs = $form.find('input:not([type=checkbox]), textarea'),
      $requiredPhone = $form.find('[data-validate-phone] input[type=tel]'),
      $requiredCheckboxes = $form.find('input[type=checkbox]'),
      $customSelects = $form.find('select'),
      $sameBillingAddress = $('input[name="same-billing-address"]'),
      $step = $(".step"),
      step = window.go4history.get('step');

    // activate mask function for special inputs
    $(document).ready(() => {
      mask();
    });

    // remove error tooltip on document upload when user selects a type
    $('body').on('change', '.file-upload-type', function () {
      $(this).closest('.teko-input').removeClass('error');
    });

    // show/hide billing details
    $sameBillingAddress.on("change selectChanged", function () {
      displayBillingField($(this).val());
    });

    displayBillingField($sameBillingAddress.filter(':checked').val());

    countrySelect();
    motherToungeSelect();

    dropzone();
    fileTypeSelect();

    window.go4history.onStateChange(function () {
      goToStep(window.go4history.get('step'));
    });

    // add event listeners for validation
    $requiredPhone.on("paste", function () {
      // short delay so it gets checked after the input is filled
      if ($(this).closest('.error').length) {
        setTimeout(function () {
          updateStepValidity($(this));
        }, 100);
      }
    })

    $requiredPhone.on("keyup click", function () {
      if ($(this).closest('.error').length) {
        updateStepValidity($(this));
      }
    });


    $requiredInputs.on("paste", function () {
      // short delay so it gets checked after the input is filled
      if ($(this).closest('.error').length) {
        setTimeout(function () {
          updateStepValidity($(this));
        }, 100);
      }
    })
    $requiredInputs.on("keyup click", function () {
      if ($(this).closest('.error').length) {
        updateStepValidity($(this));
      }
    });

    $requiredCheckboxes.on("change", function () {
      if ($(this).closest('.error').length) {
        updateStepValidity($(this));
      }
    });
    $customSelects.on("change selectChanged", function () {
      if ($(this).closest('.error').length) {
        updateStepValidity($(this));
      }
    });

    $("#nationality").on("change selectChanged", function () {
      var $select2 = $(this).parents(".teko-input-select2");

      if (this.value) {
        $select2.addClass("select-filled");
      } else {
        $select2.removeClass("select-filled");
      }
    });

    // validate each step on load
    $step.each(function () {
      var $inputs = $(this).find('input, textarea');

      if ($inputs.length) {
        $inputs.each(function () {
          updateStepValidity($(this), 'on-load-error');
          return false;
        });
      }
    });

    checkClickables();

    // go to step if given from url
    if (step) goToStep(step);
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 50);

    $(".progress-flow .progress-section .progress-circle").on("click", function () {
      var step = $(this).parent().data("step");

      if (step == 6) {
        return;
      }

      if (step == 5) {
        // reload for last step to show newest data
        window.go4history.push('step', 5);
        location.reload();
      } else {
        goToStep(step);
      }
    });

    // submit behavior
    $form.on("submit", async function (e) {
      e.preventDefault();

      // check validity of current step
      var stepNr = window.go4history.get('step') || 1;
      var $step = $form.find('.step[data-step=' + stepNr + ']');

      if (!updateStepValidity($step)) {
        // scroll to first invalid
        $form.find('.error').eq(0);
        $('html, body').stop().animate({scrollTop: $form.find('.error').eq(0).offset().top - 160}, 500, 'swing');
        return;
      }

      $form.addClass("loading");

      var action = $form.attr("action"),
        formData = new FormData($form.get(0)),
        $activeStep = $(".step.active"),
        activeStep = $activeStep.data("step");
      if (activeStep == 5) {
        formData.append('lastStep', 'true');
      }

      if ($activeStep.data("save-assets") == true) {
        formData.append('saveAssets', 'true');
      }

      if ($activeStep.data("asset-type") == 'picture') {

        let croppieImage = await getCroppieImage();

        if (croppieImage) {
          formData.append('croppieImage', croppieImage, croppieImage.name);

          // append meta data
          formData.append('imageMetaScale', window.imageMetaScale);
          formData.append('imageMetaXTransform', window.imageMetaXTransform);
          formData.append('imageMetaYTransform', window.imageMetaYTransform);
        }
      }

      $.ajax({
        url: action,
        type: 'POST',
        data: formData,
        contentType: false,
        processData: false,
        success: function (response) {
          var stillLoading = false;

          if (response.success) {
            if (activeStep == 4) {
              window.go4history.push('step', 5);
              stillLoading = true;
              location.reload();
              location.replace('#step=5');
            } else {
              window.changeStep();
            }
          } else {
            $step.removeClass("active");
            removeText();
            $form.find(".error").show();

            if (response.type && response.type == 'tocco') {
              $(".follow-up-wrapper > :not(.tocco-error)").hide();

              let $toccoError = $(".error.tocco-error");
              $toccoError.show();
              $([document.documentElement, document.body]).animate({
                scrollTop: $toccoError.offset().top
              }, 500);
            }
          }

          if (!stillLoading) {
            $form.removeClass("loading");
          }
        }
      });
    });
  }

  function getCroppieImage() {

    return new Promise((resolve, reject) => {
      // do this only if croppie is initialised (an image present)
      if (window.croppie && !window.isPictureCropped) {
        window.croppie.result('blob', {width: 1200, height: 1200}, 'jpeg', 10).then(function (blob) {
          let newFile = blob;

          // set 'cropped to true' to prevent infinite loop
          window.isPictureCropped = true;
          if (newFile) {
            newFile.cropped = true;

            // set new filename for cropped version
            newFile.name = 'cropped_' + window.cachedFilename;

            resolve(newFile);
          } else {
            resolve(false);
          }

          // save image position in viewport for later
          calcImageCustomMeta();
        });
      } else {
        resolve(false);
      }
    });
  }

  function countrySelect() {
    // special dropdown for country select
    let $nationality = $('select[name="nationality"]');

    $nationality.select2({
      containerCssClass: 'select'
    });

    var $tekoInputSelect2 = $(".teko-input-select2.nationality");
    $nationality.on('select2:open', function (e) {
      $(".select2-container").width($tekoInputSelect2.outerWidth() - 4);
      $tekoInputSelect2.addClass('open');
    });
    $nationality.on('select2:close', function (e) {
      $tekoInputSelect2.removeClass('open');
    });
    $nationality.on('select2:select', function (e) {
      $tekoInputSelect2.addClass('select-filled');
    });
  }

  function motherToungeSelect() {
    let $motherTongue = $('select[name="mothertongue"]');
    // special dropdown for country select
    $motherTongue.select2({
      containerCssClass: 'select'
    });

    var $tekoInputSelect2 = $(".teko-input-select2.mothertongue");
    $motherTongue.on('select2:open', function (e) {
      $(".select2-container").width($tekoInputSelect2.outerWidth() - 4);
      $tekoInputSelect2.addClass('open');
    });
    $motherTongue.on('select2:close', function (e) {
      $tekoInputSelect2.removeClass('open');
    });
    $motherTongue.on('select2:select', function (e) {
      $tekoInputSelect2.addClass('select-filled');
    });
  }

  function fileTypeSelect() {
    let $selects = $(".dropzone-previews select.file-upload-type:not(.select2-hidden-accessible)");

    $selects.each(function () {
      let $select = $(this);

      $select.select2({
        containerCssClass: 'select'
      });

      var $tekoInputSelect2 = $select.parents(".file-upload-type-select2");
      $select.on('select2:open', function (e) {
        $(".select2-container").width($tekoInputSelect2.outerWidth() - 4);
        $tekoInputSelect2.addClass('open');
      });
      $select.on('select2:close', function (e) {
        $tekoInputSelect2.removeClass('open');
      });
      $select.on('select2:select', function (e) {
        $tekoInputSelect2.addClass('select-filled');
      });
    });
  }

  function mask() {
    $("input[data-value]").each(function () {
      $(this).val($(this).data("value"));
    });
  }

  function updateStepValidity($step, errorClass) {
    errorClass = errorClass || 'error';
    if (errorClass === undefined) {
      errorClass = 'error';
    }

    if (!$step.hasClass('step')) {
      $step = $step.closest('.step');
    }

    let $inputs = $step.find('input:not([type=checkbox]), textarea'),
      $phone = $step.find('[data-validate-phone] input[type=tel]'),
      $checkboxes = $step.find('input[type=checkbox]').filter('[required]'),
      $selects = $step.find('select').filter('[required]'),
      valid = true;

    $inputs.each(function () {
      if (!window.validateElement(this)) {
        valid = false;
        $(this).closest('.teko-input').addClass(errorClass);
      } else {
        $(this).closest('.teko-input').removeClass(errorClass);
      }
    });

    $phone.each(function () {
      const iti = window.intlTelInputGlobals.getInstance(this);
      if (!iti.isValidNumber() && !this.required && this.value.length < 4) {
        this.value = '';
      }

      if (!this.required && this.value.trim() === "") {
        $(this).closest('.teko-input-phone').removeClass(errorClass);
        return true;
      }

      if (iti && iti.telInput && iti.isValidNumber()) {
        $(this).closest('.teko-input-phone').removeClass(errorClass);
      } else {
        valid = false;
        $(this).closest('.teko-input-phone').addClass(errorClass);
      }
    });

    $checkboxes.each(function () {
      if (!$(this).prop('checked')) {
        valid = false;
        $(this).closest('.teko-input').addClass(errorClass);
      } else {
        $(this).closest('.teko-input').removeClass(errorClass);
      }
    });

    $selects.each(function () {
      if (!$(this).val()) {
        valid = false;
        $(this).closest('.teko-input').addClass(errorClass);
      } else {
        $(this).closest('.teko-input').removeClass(errorClass);
      }
    });

    if ($step.data("save-assets") == true) {
      if ($step.data('asset-type') === 'picture' && window.pictureUpload && window.pictureUpload.files.length < 1) {
        valid = false;
        // show correct tooltip
        $('.picture-input').addClass('error');
        $('.picture-input').find('.tooltip.error-empty').removeClass('inactive');
      } else {
        $('.picture-input').removeClass('error');
        $('.picture-input').find('.tooltip.error-empty').addClass('inactive');
      }
    }

    if (valid) {
      $step.addClass('valid');
    } else {
      $step.removeClass('valid');
    }

    return valid;
  }

  function goToStep(step) {
    var $targetStep = $(".step[data-step='" + step + "']");
    if ($targetStep.length && allPreviousStepsValid(step)) {
      $('.step.active').removeClass('active');
      $targetStep.addClass('active');
      removeText();
      updateProgressFlow(step);
    }
    window.go4history.push('step', $(".step.active").data('step'));
  }

  function displayBillingField(value) {
    const $billing = $form.find('.billing');

    if (value == '0') {
      $billing.show();
      $billing.find("input[data-required='true']").attr("required", true);
    } else {
      $billing.hide();
      $billing.find("input").removeAttr("required");
    }

    updateStepValidity($billing.find(".error input"));
  }

  function dropzone() {
    var $previewElement = $('.dz-preview-template-wrapper > .dz-preview[data-dz-preview-template]'),
      previewTemplate = $previewElement.prop("outerHTML"),
      processingFiles = [],
      $step = $(".step[data-save-assets='true']"),
      $submitButton = $step.find("button.submit");

    var fileUpload = new Dropzone("#files", {
      url: "/form/filesave/?followUpId=" + window.followUpId,
      previewsContainer: ".files-upload .dropzone-previews",
      maxFilesize: window.uploader.maxFileSize,
      previewTemplate: previewTemplate,
      acceptedFiles: ".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.pdf,.PDF",

      // messages
      dictDefaultMessage: window.uploader.dictDefaultMessage,
      dictInvalidFileType: window.uploader.dictInvalidFileType,
      dictFileTooBig: window.uploader.dictFileTooBig,
      dictCancelUpload: window.uploader.dictCancelUpload,
      dictCancelUploadConfirmation: window.uploader.dictCancelUploadConfirmation,
      dictRemoveFile: window.uploader.dictRemoveFile,
      dictMaxFilesExceeded: window.uploader.dictMaxFilesExceeded,
      dictFallbackMessage: window.uploader.dictFallbackMessage,

      thumbnailWidth: 1200,
      thumbnailHeight: 1200,

      init: function () {
        // do not make preview select required
        $previewElement.find('select.file-upload-type').removeAttr('required');

        this.on("error", function (file, message) {
          $(file.previewElement).find('.teko-input-select2').hide();
          if (window.fileUpload.files.length) {
            window.fileUpload.removeFile(file);

            $('.dropzone-previews .static-tooltip.toobig').addClass('show');
          }
        });

        this.on("removedfile", function (file) {
          $.get("/form/fileremove?filename=" + file.name + "&followUpId=" + window.followUpId);

          removeProcessingFile(file);

          updateStepValidity($step, 'on-load-error');
        });
        this.on("addedfile", function (file) {
          $('.dropzone-previews .static-tooltip').removeClass('show');
          let fileName = file.name;
          let $uploadEms = $('.files-upload span[data-dz-name]');
          let count = false;

          $uploadEms.each(function () {
            if (fileName === $(this).text())
              count++
          });

          if (count > 1) {
            $(file.previewElement).find('.teko-input-select2').hide();

            if (window.fileUpload.files.length) {
              window.fileUpload.removeFile(file);

              $('.dropzone-previews .static-tooltip.exists').addClass('show');
            }
          }
        });
        this.on("processing", function (file) {
          //$('.dropzone-previews .static-tooltip').removeClass('show');
          $submitButton.addClass('btn-disabled');

          fileTypeSelect();
          setFileSelectName(file);

          processingFiles.push(file);
        });
        this.on("success", function (file) {
          removeProcessingFile(file);
        });
      }
    });
    window.fileUpload = fileUpload;

    $.each(window.uploader.uploadDocuments, function (index, value) {
      preAddFile(fileUpload, value);

      setFileSelectName(value);
      setFileSelectValue(value);
    });

    if ($('#picture').length > 0) {
      var pictureUpload = new Dropzone("#picture", {
        uploadMultiple: false,
        maxFiles: 1,
        url: "/form/filesave?fileType=picture&followUpId=" + window.followUpId,
        previewsContainer: ".picture-upload .dropzone-previews",
        maxFilesize: window.uploader.maxFileSize,
        //previewTemplate: previewTemplate,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF",
        clickable: '#picture',

        // messages
        dictDefaultMessage: window.uploader.dictDefaultMessage,
        dictInvalidFileType: window.uploader.dictInvalidFileType,
        dictFileTooBig: window.uploader.dictFileTooBig,
        dictCancelUpload: window.uploader.dictCancelUpload,
        dictCancelUploadConfirmation: window.uploader.dictCancelUploadConfirmation,
        dictRemoveFile: window.uploader.dictRemoveFile,
        dictMaxFilesExceeded: window.uploader.dictMaxFilesExceeded,
        dictFallbackMessage: window.uploader.dictFallbackMessage,

        init: function () {
          $croppieElement = $('.croppie-element');

          this.on("error", function (file, message) {
            // show tooltip
            console.error(message);

            $('.picture-input').addClass('error');
            $('.picture-input').find('.tooltip.error-too-big').removeClass('inactive');

            window.clearImageInterval = setInterval(window.changeProfilePicture, 50);
          });

          this.on("thumbnail", function (file) {
            if (file.width < 300 || file.height < 300) {

              $('.picture-input').addClass('error');
              $('.picture-input').find('.tooltip.error-too-small').removeClass('inactive');

              // remove file and show input
              setTimeout(function () {
                window.changeProfilePicture();
              }, 100);

            }

            // cache filename to re-assign it to cropped file
            window.cachedFilename = file.name;

            let croppieResult = null;
            // dynamically create modals to allow multiple files processing
            var $cropperModal = $('<div></div>');
            var $img = $('<img id="testImg" />');

            // initialize FileReader which reads uploaded file
            var reader = new FileReader();
            reader.onloadend = function () {
              // add uploaded and read image to modal
              $cropperModal.find('.image-container').html($img);
              $img.attr('src', reader.result);

              // init croppie for newly dropped image, use original file data for cropping
              if (file instanceof Blob && $croppieElement.length > 0) {
                if (window.croppie) window.croppie.result('destroy');

                window.url = reader.result;

                if (pictureUpload.files.length) {
                  if (!window.croppie) {
                    window.croppie = new Croppie($croppieElement[0], {
                      url: reader.result,
                      viewport: {
                        width: 150,
                        height: 150,
                      },
                      boundary: {
                        width: 300,
                        height: 300
                      },
                    });
                  } else {
                    window.croppie.bind({url: reader.result});
                  }

                  $croppieElement.show();
                  $('.dropzone-input.picture-input').hide();
                }
              }
            };

            if (croppieResult) {
              file = croppieResult;
            }

            //toggle reader only for newly uploaded files
            if (file instanceof Blob) {
              reader.readAsDataURL(file);
            }
          });

          this.on("addedfile", function (file) {
            // remove all tooltips before upload and validation check
            $('.picture-input').removeClass('error');
            $('.picture-input').find('.tooltip[class*="error-"]').addClass('inactive');

            if (this.files.length > 0) {
              $('#picture').removeAttr('required');
              $('#picture-edit').show();
              $("#picture").hide();

              // init croppie for previously uploaded/saved image, use Dropzone's thumbnail src for cropping
              if (!(file instanceof Blob)) {
                setTimeout(function () {
                  if ($croppieElement.length > 0) {
                    if (window.croppie) {
                      window.croppie.croppie('destroy');
                    }

                    window.croppie = new Croppie($croppieElement[0], {
                      url: $('.dz-image > img').attr('src'),
                      viewport: {
                        width: 150,
                        height: 150,
                      },
                      boundary: {
                        width: 300,
                        height: 300
                      },
                      enableExif: true
                    });

                    $('.dropzone-input.picture-input').hide();
                  }
                }, 300);
              }
            }

            if (this.files.length > 1) {
              this.removeFile(file);
            }

            updateStepValidity($step, 'on-load-error');
          });

          this.on("removedfile", function (file) {
            $("#picture").show();
            $('#picture-edit').hide();
            window.changeProfilePicture();
            $.get("/form/fileremove?filename=" + file.name + "&fileType=picture" + "&followUpId=" + window.followUpId);
          });
        }
      });

      window.pictureUpload = pictureUpload;

      preAddFile(pictureUpload, window.uploader.uploadPicture, window.uploader.uploadPicture.thumbnail);
    }

    function removeProcessingFile(file) {
      var index = processingFiles.indexOf(file);
      if (index > -1) {
        processingFiles.splice(index, 1);
      }

      if (processingFiles.length == 0) {
        $submitButton.removeClass('btn-disabled');
      }
    }

    function preAddFile(dropzone, file, thumbnail) {
      if (file) {
        file.initial = true;

        dropzone.files.push(file);
        dropzone.emit("addedfile", file);
        if (thumbnail) {
          dropzone.emit("thumbnail", file, thumbnail);
        }
        dropzone.emit("success", file);
        dropzone.emit("complete", file);
      }
    }

    function setFileSelectName(file) {
      let $select = $(file.previewElement).find('select.file-upload-type'),
        currentName = $select.attr('name'),
        fileName = file.name.replace(/[\W_]+/g, "_");

      $select.attr('name', currentName + '_' + fileName);
    }

    function setFileSelectValue(file) {
      let $select = $(file.previewElement).find('select.file-upload-type'),
        fileName = file.name.toLowerCase(),
        value = null;

      if (fileName.startsWith('efz_')) {
        value = 'efz';
      } else if (fileName.startsWith('bm_')) {
        value = 'bm';
      } else if (fileName.startsWith('pb_hfsv')) {
        value = 'pb_hfsv';
      } else if (fileName.startsWith('dp')) {
        value = 'dp';
      } else if (fileName.startsWith('wa')) {
        value = 'wa';
      } else if (fileName.startsWith('fa')) {
        value = 'fa';
      }

      if (value) {
        $select.val(value);
        $select.parents('.file-upload-type-select2').addClass('select-filled');
      }
    }
  }
});

function calcImageCustomMeta() {
  let $image = $('.croppie-element .cr-overlay');
  let sizeBse = 1.5; // (1% from 150px viewport)
  let $vp = $('.croppie-element .cr-viewport');
  let boundaryBase = 3; // 1% from 300px boundary

  // calc just one dimension for scaling
  let imageMetaScale = ($image.outerHeight() / sizeBse) - 100;
  if (imageMetaScale < 0) {
    imageMetaScale = 0;
  }
  imageMetaScale = (imageMetaScale / 100) + 1;

  let offsetDiffY = $vp.offset().top - $image.offset().top;
  offsetDiffY = offsetDiffY < 0 ? 0 : offsetDiffY;

  let offsetDiffX = $vp.offset().left - $image.offset().left;
  offsetDiffX = offsetDiffX < 0 ? 0 : offsetDiffX;

  let heightDiff = $image.outerHeight() - $vp.outerHeight();
  heightDiff = heightDiff < 0 ? 0 : heightDiff;
  let widthDiff = $image.outerWidth() - $vp.outerWidth();
  widthDiff = widthDiff < 0 ? 0 : widthDiff;

  // get Y axis
  let yTransform = (offsetDiffY - (heightDiff / 2));
  yTransform = yTransform / boundaryBase;
  yTransform = yTransform < 0 ? Math.abs(yTransform) : -Math.abs(yTransform); // switch sign for css transform

  // get X axis
  let xTransform = (offsetDiffX - (widthDiff / 2));
  xTransform = xTransform / boundaryBase;
  xTransform = xTransform < 0 ? Math.abs(xTransform) : -Math.abs(xTransform); // switch sign for css transform

  window.imageMetaScale = imageMetaScale;
  window.imageMetaXTransform = xTransform;
  window.imageMetaYTransform = yTransform;
}

window.changeStep = function (operation) {
  var $currentStep = $(".step.active"),
    currentStepNr = $currentStep.data("step"),
    targetStepNr = 1;

  if (operation == 'previous') {
    targetStepNr = parseInt(currentStepNr) - 1;
  } else {
    targetStepNr = parseInt(currentStepNr) + 1;
  }
  var $targetStep = $(".step[data-step='" + targetStepNr + "']");

  if ($targetStep.length) {
    $(".step").removeClass('active');
    $targetStep.addClass('active');
    window.go4history.push('step', targetStepNr);
    removeText();
    updateProgressFlow(targetStepNr);

    checkClickables();

    if (targetStepNr == 6) {
      window.scrollTo(0, 0);
      $('.progress-flow-wrapper').remove();
    } else if ($('.error').css('display') !== 'none') {
      window.scrollTo(0, 0);
    }
  }
}

function updateProgressFlow(toStepNr) {
  var $flowSteps = $('.progress-flow .progress-section');

  $flowSteps.each(function () {
    var $this = $(this);
    $this.data('step') <= toStepNr ? $this.addClass('active') : $this.removeClass('active');
    removeText();
  });

  if (parseInt(toStepNr) === 8) {
    $('.progress-flow').css('display', 'none');
  } else {
    $('.progress-flow').css('display', 'flex');
  }
}

function allPreviousStepsValid(step) {
  let previousStep = step === 1 ? 1 : parseInt(step) - 1;
  let allValid = true;

  while (previousStep > 0) {
    var $previousStep = $(".step[data-step='" + previousStep + "']");

    if (!$previousStep.hasClass('valid')) {
      allValid = false;
      return;
    }

    previousStep--;
  }
  return allValid;
}

function checkClickables() {
  var $steps = $(".step");

  if ($steps.length > 0) {
    $steps.each(function () {
      var $this = $(this),
        stepNr = $this.data("step"),
        $progressSection = $('.progress-flow .progress-section[data-step="' + stepNr + '"]');

      if (stepNr != 6 && allPreviousStepsValid(stepNr)) {
        $progressSection.addClass('clickable');
      } else {
        $progressSection.removeClass('clickable');
      }
    });
  }
}

function removeText() {
  if ($(".clearfix[data-step='6']").hasClass("active")) {
    $(".header-text").hide();
  } else {
    $(".header-text").show();
  }
}

window.changeProfilePicture = function () {
  $('#picture-edit').hide();
  $('input#picture').show();
  $('input#picture').attr('required', true);
  $('.picture-input').show();
  $('.croppie-element').hide();
  $('.cropped-image').hide();

  if (window.pictureUpload.files.length) {
    window.pictureUpload.removeAllFiles();
    pictureUpload.files = [];
  }

  $('div.step[data-asset-type="picture"]').attr('data-save-assets', true);

  // stop interval as soon as image is cleared
  if ($('#picture').prop('required')) {
    clearInterval(window.clearImageInterval);
  }
}
