$(function(e) {
    if ($(".idle-screen section.page-course-detail").length > 0) {
        var $accordions = $(".page-course-detail .accordion-item");

        // check if param is passed in url
        if (window.go4history.get("open-accordions")) {
            // get passed accordions as array
            var values = window.go4history.get("open-accordions").split('+');

            $.each(values, function (index, value) {
                // go through each accordion, check if it exists and open it
                var $targetAccordion = $(".page-course-detail .accordion-item[data-accordion='" + value + "']");

                if ($targetAccordion.length > 0) {
                    $targetAccordion.addClass("open");
                }
            });
        } else {
            // if no params were passed set default accordion to open
            var $defaultAccordion = $(".page-course-detail .accordion-item[data-accordion='description']");

            if ($defaultAccordion) {
              $defaultAccordion.addClass("open");
              window.go4history.update("open-accordions", $defaultAccordion.data("accordion"))
            }
        }

        $accordions.on("click", function (e) {
            var values = [],
                accordionName = $(this).data("accordion");

            // check if current values exist and convert to array
            if (window.go4history.get("open-accordions")) {
                values = window.go4history.get("open-accordions").split('+');
            }

            if ($(this).hasClass("open")) {
                // add opened accordion to array
                if ($.inArray(accordionName, values) === -1) {
                    values.push(accordionName);
                }
            } else {
                // remove closed accordion from array
                values = $.grep(values, function (value) {
                    return value !== accordionName;
                });
            }

            // convert to string and update url
            var valuesString = values.join('+');
          window.go4history.update("open-accordions", valuesString);
        });

        // check for active accordion in url
        if (window.go4history.get("active-accordion")) {
            var $targetAccordion = $(".page-course-detail .accordion-item[data-accordion='" + window.go4history.get("active-accordion") + "']");

            setTimeout(function() {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $targetAccordion.offset().top - $(".sticky-curtain-wrapper").outerHeight() + 1
                }, 200);
            }, 1600);
        }

        // scroll spy
        $(window).scroll(function () {
            var scrollPosition = $(document).scrollTop(),
                hasMatch = false,
                offset = $(".sticky-curtain-wrapper").outerHeight();

            // check each accordion
            $accordions.each(function () {
                var topPosition = $(this).offset().top - offset,
                    bottomPosition = $(this).offset().top + $(this).outerHeight(true) - offset;

                if (topPosition < 0 || bottomPosition < 0) {
                    return false;
                }

                // check if current scroll position is in between the accordion
                if (scrollPosition >= topPosition && scrollPosition <= bottomPosition && $(this).hasClass("open")) {
                  window.go4history.update("active-accordion", $(this).data("accordion"));
                    hasMatch = true;
                    return false;
                }
            });

            // if the current scroll position is not in between any accordion -> set active to empty
            if (hasMatch === false) {
              window.go4history.update("active-accordion", "");
            }
        });
    }

    if (window.getUrlParam('open-consultation')) {
        window.openOverlayByName('consultation-form');
    }

    if (window.getUrlParam('open-course')) {
        window.openOverlayByName('course-form');
    }

    if (window.getUrlParam('downloadId')) {
        window.openOverlayByName('download');
    }
});
