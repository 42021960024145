$(function () {
  if ($('.idle-screen .page-jobs-overview').length) {
    let location = '';
    if (!window.go4history.get("location")) {
      let location = $('.page-jobs-overview .categories li.active').data('location');
      location = location ? location : 'all';
    }

    if (window.go4history.get("job")) {
      window.openJobOverlay(window.go4history.get("job"));
    }

    $('.page-jobs-overview .categories li').removeClass('active');
    $('.page-jobs-overview .categories li[data-location="' + window.go4history.get("location") + '"]').addClass("active");

    window.showJobTeasers(window.go4history.get("location"));

    $('.page-jobs-overview .categories li').on('click', function () {
      if (!$(this).hasClass('active')) {
        window.updateJobTeasers($(this));
      }
    });

    $('html').on('click', '.job-overlay .btn-remove-file', (e) => {
      let $input = $(e.originalEvent.target).closest('.teko-input').find('input.job-file[type="file"]');
      $input.val('');
      $input.trigger('change');
    });
  }
});

window.go4history.onStateChange( () => {
  const $activeElem = $('.page-jobs-overview .categories li[data-location="' + window.go4history.get("location") + '"]');
  window.updateJobTeasers($activeElem);
});

window.updateJobTeasers = ($activeElem) => {
  window.go4history.push("location", $activeElem.data("location"));

  window.showJobTeasers(window.go4history.get("location"));
  $('.page-jobs-overview .categories li').removeClass('active');
  $activeElem.addClass('active');
}

window.showJobTeasers = (location) => {
  let selector = !location || location === 'all' ? '' : '[data-location="' + location + '"]';
  let $noJobsMessage = $('.no-jobs div');
  let $allJobs = $('.b-jobs-list > ul > li');
  let $jobsToShow = $('.b-jobs-list > ul > li' + selector);

  $allJobs.addClass('hidden');

  setTimeout( () => {
    $allJobs.hide();
    $noJobsMessage.hide().addClass('hidden');
    $('.no-jobs').addClass('show');

    if ($jobsToShow.length === 0) {
      $noJobsMessage.filter('[data-location="' + location + '"]').show().removeClass('hidden');
    }

    if(selector === '') {
      $jobsToShow.filter('[data-blind!=true]').show();
    } else {
      $jobsToShow.show();
    }

    setTimeout( ()=> {
      $jobsToShow.removeClass('hidden');
    }, 10);
  }, 300); // css transition length
}
