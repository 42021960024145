window.checkForInboundLocationHash = function() {
    let hashData = getLocationHashValues();
    if (hashData) {
        if (hashData['inbound']) {
            window.openOverlayByName("overlay-inbound-download-e-book");
        }
        if (hashData['beratung']) {
            window.openOverlayByName("consultation-form");
        }
        if (hashData['anmeldung']) {
            window.openOverlayByName("course-form");
        }
        if (hashData['infoanlass']) {
            window.openOverlayByName("info-events-list");
        }
    }
}

function getLocationHashValues() {
    var hash = location.hash.replace(/^.*?#/, '');
    var pairs = hash.split('&');

    let data = {};
    $.each(pairs, function( index, value ) {

        var key = value.split('=')[0];
        var keyValue = value.split('=')[1];

        if(key !== '' && keyValue !== '') {
            data[key] = keyValue;
        }
    });

    return data;
}
