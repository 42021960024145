
$(function (){
    $('.b-accordion .accordion-header').on('click', function () {
        let $toggle = $(this);

        let height = 0;
        if (!$toggle.hasClass('active')) {
            height = $toggle.find('+ .accordion-content .wrapper').outerHeight();

            $toggle.addClass('active');
        } else {
            $toggle.removeClass('active');
        }

        // Speed up the animation by setting the duration to 300 milliseconds
        $toggle.find('+ .accordion-content').animate({
            'height': height + 'px'
        }, 10);
    });

});
