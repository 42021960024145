$(function () {
    var $chatIcon = $('.open-chat'),
        onlineClass = 'icon-chat',
        offlineClass = 'icon-contact-form',
        body = $('body'),
        containerOlark = '#olark-container';

    $(document).on("click", ".open-chat", function() {
        if (typeof olark !== 'undefined') {
            olark('api.box.expand');
        }
    });

    body.on('click', function (event){
        if (!$('#olark-container').hasClass('olark-hidden')) {
            var target = $(event.originalEvent.target);
            if (target.attr('id') !== 'olark-container' && !target.closest(containerOlark).length) {
                $(containerOlark).addClass('olark-hidden');
            }
        }
    });

    $chatIcon.on('click', function(event) {
        event.stopPropagation();
        if ($(containerOlark).hasClass('olark-hidden')) {
            $(containerOlark).removeClass('olark-hidden');
        } else {
            $(containerOlark).addClass('olark-hidden');
        }
    });

    if (typeof olark !== 'undefined') {
        olark('api.chat.onOperatorsAvailable', function () {
            window.olarkState = 'available';
            updateChatIcon();
        });
        olark('api.chat.onOperatorsAway', function () {
            window.olarkState = 'away';
            updateChatIcon();
        });
    }

    function updateChatIcon() {
        if (window.olarkState === 'available') {
            $chatIcon.removeClass(offlineClass);
            $chatIcon.addClass(onlineClass);
        } else if (window.olarkState !== '') {
            $chatIcon.removeClass(onlineClass);
            $chatIcon.addClass(offlineClass);
        }
    }

    updateChatIcon();
});
