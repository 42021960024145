export default {
    badge: null,

    config: {
        selector: '.b-course-date-badge:not(.clone)',
    },

    mounted() {
        // avoid longer animation timeouts by trying to init badge with minimal interval
        let mountBadgeTimeout = setInterval(() => {
            this.cloneDataBadge();
            if (this.badge && this.badge.length) {
                this.moveDataBadge();
                clearInterval(mountBadgeTimeout);
            }
         }, 100);

        $(window).on('resize scroll', () => {
            this.mobileFadeOut();
        });
        $(window).on('resize', () => {
            this.moveDataBadge();
        });
    },

    cloneDataBadge() {
        let $badge = $(this.config.selector);
        if ($badge.length) {
            this.badge = $badge.clone();
            this.badge.addClass('clone');
            $badge.remove();
        }
    },

    moveDataBadge() {
        if ($(window).width() < 992) {
            $('nav').append(this.badge);
        } else {
            $('.page-course-detail, .page-default').append(this.badge);
        }
        setTimeout(() => {
            $('.b-course-date-badge').removeClass('hidden');
        }, 200);
    },

    mobileFadeOut() {
        let badge = document.querySelector('.b-course-date-badge');
        if (!badge) {
            return;
        }

        if (window.innerWidth < 992 && window.scrollY > 100) {
            badge.classList.add('hidden');
        } else {
            badge.classList.remove('hidden');
        }
    },

    forceFadeOut() {
        let badge = document.querySelector('.b-course-date-badge');
        if (badge) {
            badge.classList.add('hidden');

            setTimeout(() => {
                badge.remove();
            }, 300);
        }
    }
};
