$(function () {

    var initialLayout = $(window).width() >= 992 ? 'd' : 'm',
        filterKeys = {},
        $theses = $("li.thesis:not('.empty-text')");

    if ($(".idle-screen .page-thesis-overview").length) {
        $('.search-wrapper .btn-filter')
            .add('.search-wrapper input')
            .on('click', function () {
                if ($(window).width() >= 992) {
                    $('html, body').animate({
                        scrollTop: $("h1").offset().top - 60
                    }, 400, 'swing');
                }
            });

        $.each(window.go4history.getAll(), function (filter, values) {
            if (filter == 'suche') {
                $(".search-wrapper input").val(decodeURIComponent(values));
            } else {
                setTimeout(function () {
                    var valueArray = values.split(",");
                    $.each(valueArray, function (key, value) {
                        $('.desktop-filters input[data-filter-by="' + filter + '"][data-filter-value="' + value + '"]').prop("checked", true).change();
                    });
                }, 100);
            }
        });

        updateFilter();

        var typingTimer,
            doneTypingInterval = 200,
            $input = $(".search-wrapper input");

        $input.keyup(function () {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval);
        });
    }

    function doneTyping() {
        updateFilter();
    }

    function updateFilter() {
        $('section.page-thesis-overview').parents('body').addClass('theses-loading');

        setTimeout(function () {
            filterKeys = {
                "fachrichtung" : [],
                "studiengang" : [],
                "standort" : [],
                "jahr" : [],
                "specials" : [],
                "suche" : []
            };

            $('.desktop-filters input:checked.filter-checkbox:not(.toggle-all)').each(function () {
                filterKeys[$(this).data('filter-by')].push($(this).data('filter-value'));
            });
            filterKeys['suche'].push($('.search-wrapper input').val());

            window.go4history.updateValues(filterKeys);

            var count = 0,
                hasMoreToShow = false,
                $btnLoadMore = $(".btn-load-more"),
                emptyResult = true,
                $emptyText = $("li.thesis.empty-text");

            $theses.each(function() {
                var $this = $(this),
                    hide,
                    show = {};

                $.each(filterKeys, function(key, filter) {
                    if (filter.length) {
                        if (key == 'suche') {
                            show[key] = $this.data(key).indexOf(decodeURIComponent(filter).replace(/\s/g, '')) > -1;
                        } else {
                            show[key] = $.inArray($this.data(key), filter);
                        }
                    }
                });

                $.each(show, function(key, value) {
                    if (value == -1 || value === false) hide = true;
                });

                if (hide) {
                    $this.hide();
                } else {
                    if (count < $(".thesis-list-wrapper").data("thesis-count")) {
                        $this.show();
                    } else {
                        hasMoreToShow = true;
                    }
                    count++;
                    emptyResult = false;
                }

                if (hasMoreToShow) {
                    $btnLoadMore.show();
                } else {
                    $btnLoadMore.hide();
                }

                if (emptyResult) {
                    $emptyText.show();
                } else {
                    $emptyText.hide();
                }
            });

            $('a.btn-primary.btn-pink span.count').html(count);

            if (count == 1) {
                $('.filter-overlay a.btn-primary span.single').hide();
                $('.filter-overlay a.btn-primary span.multiple').show();
            } else {
                $('.filter-overlay a.btn-primary span.single').show();
                $('.filter-overlay a.btn-primary span.multiple').hide();
            }

            $('section.page-thesis-overview').parents('body').removeClass('theses-loading-b');
            $('section.page-thesis-overview').parents('body').removeClass('theses-loading');
        }, 300);
    }

    /**
     * Detail-Page: "Special" Tag in Title
     */
    if ($('.page-thesis-detail h1.special').length > 0) {
        var rawText = $('.page-thesis-detail h1.special').html();
        var lastChar = rawText.substr(rawText.length - 1);

        rawText = rawText.replace(/.$/,'<span>' + lastChar + '<div class="tag-special">Special</div></span>');
        $('.page-thesis-detail h1.special').html(rawText);

    }

    $('.btn-filter').on('click', function (e) {
        e.preventDefault();
        if ($(window).width() > 992) {
            $(this).parents('.filter-wrapper').toggleClass('filters-open');
        } else {
            var overlayEm = $('.overlay[data-overlay-name="mobile-filters"]');
            $('body').addClass('overlay-open');
            overlayEm.addClass('open');
            $('.overlay.last-opened').removeClass('last-opened');
            overlayEm.addClass('last-opened');
        }
    });

    $('.filter-options-list li span.label').on('click', function () {

        var isLastOpen = $(this).parent().hasClass('open');

        $('.filter-options-list li.open').removeClass('open');

        $(this).parent().addClass(isLastOpen ? '' : 'open');
    });

    /**
     * Dropdown -> "All" option toggle & Tags
     */

    $('input.filter-checkbox').on('change', function () {

        var _this = $(this);

        if (_this.hasClass('toggle-all')) {

            var checkboxes = _this.parents('.options-dropdown').find('.filter-checkbox');

            // do not check hidden
            checkboxes.each(function () {
                var isChecked = (_this.prop('checked') && $(this).parents('.child-group.hidden').length < 1);
                $(this).prop('checked', isChecked);
            });

        } else {
            _this.parents('.options-dropdown').find('.filter-checkbox.toggle-all').prop('checked', false);
        }

        // add tags
        $('.desktop-filters input.filter-checkbox').each(function () {
            var _this = $(this);

            // ignore toggle-all
            if (!_this.hasClass('toggle-all')) {
                if (_this.prop('checked') && !_this.hasClass('tagged')) {
                    addFilterTag(_this.find('+ label').text(), _this.data('tag-key'));
                    _this.addClass('tagged');

                } else if (!_this.prop('checked') && _this.hasClass('tagged')) {
                    removeFilterTag(_this.data('tag-key'));
                    _this.removeClass('tagged');
                }
            }
        });
    });

    $('html').on('click', '.filter-tags .tag .close', function () {
        removeFilterSelection($(this).parents('.tag').data('tag-key'));
        mergeFilterCheckboxes(false);
        updateFilter();
    });

    /**
     * Load Theses Btn
     */
    $('.page-thesis-overview .btn-load-more').on('click', function (e) {
        e.preventDefault();
        $('section.page-thesis-overview').parents('body').addClass('theses-loading-b');
        loadTheses();
    });

    /**
     * Fachrichtung Filter Animierung
     */

    var parentFiltersDesktop = [];
    var parentFiltersMobile = [];

    function updateParentDropdowns() {
        // desktop
        $('li.parent-filter input').on('change', function () {
            $('li.parent-filter').removeClass('open');
            checkDataDesktop();
        });

        // mobile
        $('.filter-slider.parent-mobile-filter input').on('change', function () {
            $('.filter-slider.parent-mobile-filter').removeClass('open');

            parentFiltersMobile["all"] = ($('.filter-slider.parent-mobile-filter input.toggle-all').prop('checked'));
            parentFiltersMobile["technik"] = ($('.filter-slider.parent-mobile-filter input.toggle-technik').prop('checked'));
            parentFiltersMobile["wirtschaft"] = ($('.filter-slider.parent-mobile-filter input.toggle-wirtschaft').prop('checked'));
            parentFiltersMobile["handel"] = ($('.filter-slider.parent-mobile-filter input.toggle-handel').prop('checked'));

            (parentFiltersMobile["all"]) ? $('.mobile-dropdown .child-group').addClass('hidden') : '';
            (parentFiltersMobile["technik"]) ? $('.mobile-dropdown .child-group.technik').removeClass('hidden') : $('.mobile-dropdown .child-group.technik').addClass('hidden');
            (parentFiltersMobile["wirtschaft"]) ? $('.mobile-dropdown .child-group.wirtschaft').removeClass('hidden') : $('.mobile-dropdown .child-group.wirtschaft').addClass('hidden');
            (parentFiltersMobile["handel"]) ? $('.mobile-dropdown .child-group.handel').removeClass('hidden') : $('.mobile-dropdown .child-group.handel').addClass('hidden');

            if (!parentFiltersMobile["technik"] && !parentFiltersMobile["wirtschaft"] && !parentFiltersMobile["handel"]) {
                $('.mobile-dropdown.child-dropdown').addClass('hidden');
            } else {
                $('.mobile-dropdown.child-dropdown').removeClass('hidden');
            }

            // unselect children
            if (!parentFiltersMobile["technik"]) {
                $('.mobile-dropdown.child-dropdown .child-group.technik input').each(function () {
                    $(this).prop('checked', false);
                });
            }

            if (!parentFiltersMobile["wirtschaft"]) {
                $('.mobile-dropdown.child-dropdown .child-group.wirtschaft input').each(function () {
                    $(this).prop('checked', false);
                });
            }

            if (!parentFiltersMobile["handel"]) {
                $('.mobile-dropdown.child-dropdown .child-group.handel input').each(function () {
                    $(this).prop('checked', false);
                });
            }

            updateMobileLabels();
        });

    }

    function checkDataDesktop() {
        parentFiltersDesktop["technik"] = ($('li.parent-filter input.toggle-technik').prop('checked'));
        parentFiltersDesktop["wirtschaft"] = ($('li.parent-filter input.toggle-wirtschaft').prop('checked'));
        parentFiltersDesktop["handel"] = ($('li.parent-filter input.toggle-handel').prop('checked'));

        (parentFiltersDesktop["technik"]) ? $('.child-group.technik').removeClass('hidden') : $('.child-group.technik').addClass('hidden');
        (parentFiltersDesktop["wirtschaft"]) ? $('.child-group.wirtschaft').removeClass('hidden') : $('.child-group.wirtschaft').addClass('hidden');
        (parentFiltersDesktop["handel"]) ? $('.child-group.handel').removeClass('hidden') : $('.child-group.handel').addClass('hidden');

        if (!parentFiltersDesktop["technik"] && !parentFiltersDesktop["wirtschaft"] && !parentFiltersDesktop["handel"]) {
            $('li.child-filter').addClass('hidden');
        } else {
            $('li.child-filter').removeClass('hidden');
        }

        if (!parentFiltersDesktop["technik"]) {
            $('li.child-filter .child-group.technik input').each(function () {
                removeFilterSelection($(this).data('tag-key'));
            });
        }

        if (!parentFiltersDesktop["wirtschaft"]) {
            $('li.child-filter .child-group.wirtschaft input').each(function () {
                removeFilterSelection($(this).data('tag-key'));
            });
        }

        if (!parentFiltersDesktop["handel"]) {
            $('li.child-filter .child-group.handel input').each(function () {
                removeFilterSelection($(this).data('tag-key'));
            });
        }
    }

    toggleChildDropdowns();

    function toggleChildDropdowns() {
        updateParentDropdowns();

        parentFiltersDesktop["technik"] = ($('li.parent-filter input.toggle-technik').prop('checked'));
        parentFiltersDesktop["wirtschaft"] = ($('li.parent-filter input.toggle-wirtschaft').prop('checked'));
        parentFiltersDesktop["handel"] = ($('li.parent-filter input.toggle-handel').prop('checked'));

        (parentFiltersDesktop["technik"]) ? $('.child-group.technik').removeClass('hidden') : $('.child-group.technik').addClass('hidden');
        (parentFiltersDesktop["wirtschaft"]) ? $('.child-group.wirtschaft').removeClass('hidden') : $('.child-group.wirtschaft').addClass('hidden');
        (parentFiltersDesktop["handel"]) ? $('.child-group.handel').removeClass('hidden') : $('.child-group.handel').addClass('hidden');

        parentFiltersMobile["technik"] = ($('.filter-slider.parent-mobile-filter input.toggle-technik').prop('checked'));
        parentFiltersMobile["wirtschaft"] = ($('.filter-slider.parent-mobile-filter input.toggle-wirtschaft').prop('checked'));
        parentFiltersMobile["handel"] = ($('.filter-slider.parent-mobile-filter input.toggle-handel').prop('checked'));

        (parentFiltersMobile["technik"]) ? $('.mobile-dropdown .child-group.technik').removeClass('hidden') : $('.mobile-dropdown .child-group.technik').addClass('hidden');
        (parentFiltersMobile["wirtschaft"]) ? $('.mobile-dropdown .child-group.wirtschaft').removeClass('hidden') : $('.mobile-dropdown .child-group.wirtschaft').addClass('hidden');
        (parentFiltersMobile["handel"]) ? $('.mobile-dropdown .child-group.handel').removeClass('hidden') : $('.mobile-dropdown .child-group.handel').addClass('hidden');

        if (!parentFiltersDesktop["technik"] && !parentFiltersDesktop["wirtschaft"] && !parentFiltersDesktop["handel"]) {

            $('li.child-filter').addClass('hidden');
        } else {

            $('li.child-filter').removeClass('hidden');
        }

        if (!parentFiltersMobile["technik"] && !parentFiltersMobile["wirtschaft"] && !parentFiltersMobile["handel"]) {

            $('.mobile-dropdown.child-dropdown').addClass('hidden');
        } else {

            $('.mobile-dropdown.child-dropdown').removeClass('hidden');
        }
    }

    function updateMobileLabels() {
        var count = $('.mobile-dropdown.child-dropdown input:checked').length;

        if ($('.mobile-dropdown.child-dropdown input:checked.toggle-all').length > 0) {
            count--;
        }

        if (count < 1) {
            $('.mobile-dropdown.child-dropdown').removeClass('has-checked');
            $('.mobile-dropdown.child-dropdown input:checked.toggle-all').prop('checked', false);
            $('.mobile-dropdown.child-dropdown').find('span.label sup').remove();
        } else {
            $('.mobile-dropdown.child-dropdown').find('span.label sup').text('(' + count + ')');
        }
    }

    function loadTheses() {
        var $listWrapper = $(".thesis-list-wrapper"),
            currentCount = $listWrapper.data("thesis-count"),
            initialCount = $listWrapper.data("thesis-initial-count");

        $listWrapper.data("thesis-count", parseInt(currentCount) + parseInt(initialCount));
        updateFilter();

    }

    function addFilterTag(text, key) {
        if ($('.tag[data-tag-key="' + key + '"]').length < 1) {

            $('.filter-tags').append($('<div class="tag" data-tag-key="' + key + '">' + text + '<span class="close"></span></div>'));
        }
    }

    function removeFilterTag(key) {
        $('.filter-tags .tag[data-tag-key="' + key + '"]').remove();
    }

    function mergeFiltertags() {

        // always by desktop
        $('.desktop-filters input').each(function () {
            var _this = $(this);

            if (_this.prop('checked')){
                if ($('.tag[data-tag-key="' + _this.data('tag-key') + '"]').length < 1) {

                    if (!_this.hasClass('toggle-all')) {
                        addFilterTag(_this.find('+ label').text(), _this.data('tag-key'));
                    }
                }
            } else {
                removeFilterTag(_this.data('tag-key'))
            }
        });
    }

    function removeFilterSelection(key) {
        $('input[data-tag-key="' + key + '"]').prop('checked', false);
        $('input[data-tag-key="' + key + '"]').removeClass('tagged');
        removeFilterTag(key);

        mergeFilterCheckboxes(false);
    }

    /**
     * Apply desktop selections to mobile and vice-versa, update filter keys
     */
    function mergeFilterCheckboxes(byMobile) {

        var primarySelector = byMobile ? '.filter-overlay' : '.desktop-filters';
        var secondarySelector = byMobile ? '.desktop-filters' : '.filter-overlay';

        var primaryElements = $(primarySelector + ' input:not(.toggle-all)');

        primaryElements.each(function () {
            $(secondarySelector + ' input[data-filter-by="' + $(this).data('filter-by') + '"][data-filter-value="' + $(this).data('filter-value') + '"]').prop('checked', $(this).prop('checked'));
        });

        if (!byMobile){
            updateMobileLabels();
        }

        mergeFiltertags();

        // check data
        parentFiltersDesktop["technik"] = ($('li.parent-filter input.toggle-technik').prop('checked'));
        parentFiltersDesktop["wirtschaft"] = ($('li.parent-filter input.toggle-wirtschaft').prop('checked'));
        parentFiltersDesktop["handel"] = ($('li.parent-filter input.toggle-handel').prop('checked'));

        (parentFiltersDesktop["technik"]) ? $('.child-group.technik').removeClass('hidden') : $('.child-group.technik').addClass('hidden');
        (parentFiltersDesktop["wirtschaft"]) ? $('.child-group.wirtschaft').removeClass('hidden') : $('.child-group.wirtschaft').addClass('hidden');
        (parentFiltersDesktop["handel"]) ? $('.child-group.handel').removeClass('hidden') : $('.child-group.handel').addClass('hidden');

        parentFiltersMobile["technik"] = ($('.filter-slider.parent-mobile-filter input.toggle-technik').prop('checked'));
        parentFiltersMobile["wirtschaft"] = ($('.filter-slider.parent-mobile-filter input.toggle-wirtschaft').prop('checked'));
        parentFiltersMobile["handel"] = ($('.filter-slider.parent-mobile-filter input.toggle-handel').prop('checked'));

        (parentFiltersMobile["technik"]) ? $('.mobile-dropdown .child-group.technik').removeClass('hidden') : $('.mobile-dropdown .child-group.technik').addClass('hidden');
        (parentFiltersMobile["wirtschaft"]) ? $('.mobile-dropdown .child-group.wirtschaft').removeClass('hidden') : $('.mobile-dropdown .child-group.wirtschaft').addClass('hidden');
        (parentFiltersMobile["handel"]) ? $('.mobile-dropdown .child-group.handel').removeClass('hidden') : $('.mobile-dropdown .child-group.handel').addClass('hidden');

        if (!parentFiltersDesktop["technik"] && !parentFiltersDesktop["wirtschaft"] && !parentFiltersDesktop["handel"]) {

            $('li.child-filter').addClass('hidden');
        } else {
            $('li.child-filter').removeClass('hidden');
        }

        if (!parentFiltersMobile["technik"] && !parentFiltersMobile["wirtschaft"] && !parentFiltersMobile["handel"]) {
            $('.mobile-dropdown.child-dropdown').addClass('hidden');
        } else {
            $('.mobile-dropdown.child-dropdown').removeClass('hidden');
        }
    }

    $('.filter-overlay .mobile-dropdown span.label').on('click', function () {
        var isPreOpened = $(this).parents('.mobile-dropdown').hasClass('open');

        if (isPreOpened) {
            $(this).parents('.mobile-dropdown').removeClass('open');
        } else {
            $('.filter-overlay .mobile-dropdown.open').removeClass('open');
            $(this).parents('.mobile-dropdown').addClass('open');
        }
    });

    $('html body').on('click', function (e) {
        if (e.originalEvent) {
            if (!$(e.originalEvent.target).hasClass('label') && $(e.originalEvent.target).parents('.filter-options-list').length < 1) {
                $('.filter-options-list li.open').removeClass('open');
            }

            if (!$(e.originalEvent.target).hasClass('label') && $(e.originalEvent.target).parents('.mobile-dropdown').length < 1) {
                $('.mobile-dropdown.open').removeClass('open');
            }
        }
    });

    /**
     * Mobile Filter Dropdown
     */
    $('.filter-overlay input').on('change', function () {
        var parent = $(this).parents('.mobile-dropdown');

        if (parent.find('input:checked').length > 0) {
            parent.addClass('has-checked');

            parent.find('span.label sup').remove();

            var text = parent.find('span.label').html();
            var count = parent.find('input:checked').length;

            if (parent.find('input:checked.toggle-all').length > 0) {
                count--;
            }


            if (count > 0) {
                if (!parent.find('span.label').hasClass('replace')) {
                    parent.find('span.label').html(text + '<sup>(' + count + ')</sup>');
                } else {
                    parent.find('span.label').html(parent.find('input:checked').parent().find('label').text());
                }
            }

        } else {
            parent.removeClass('has-checked');
            parent.find('span.label sup').remove();
        }

        mergeFilterCheckboxes(true);
        updateFilter();
    });

    $('.desktop-filters input').on('change', function () {
        mergeFilterCheckboxes(false);
        updateFilter();
    });

    /**
     * Text clear btn
     */
    $('span.clear-text').on('click', function () {
        $(this).parent().find('input[name="search"]').val('');
        updateFilter();
    });
});
