/**
 * Overlay Open / Close
 */

window.preClosedOverlays = [],
  savedScrollPos = 0,
  overlayloading = false,
  additionalBodyClass = "";

window.openOverlayByName = function (overlayName, isPreClosed) {
  isPreClosed = isPreClosed || false;
  var overlayEm = $('.overlay[data-overlay-name="' + overlayName + '"]');

  if (overlayEm.length > 0) {
    // save current scroll pos
    savedScrollPos = $(window).scrollTop();

    // check if any overlay is already opened
    if ($('body').hasClass('overlay-open')) {

      if (!isPreClosed) {
        var preClosed = $('.overlay.last-opened');
        window.preClosedOverlays.push(preClosed);
        window.closeOverlay(preClosed, false, true);
        overlayEm.addClass('has-prev');
      }
    } else {
      $('body').addClass('overlay-open');
      $('body').addClass(additionalBodyClass);

      // add hash to url on specific overlays
      switch (overlayName) {
        case 'consultation-form':
          window.location.hash = "beratung=1";
          break;
        case 'course-form':
          window.location.hash = "anmeldung=1";
          break;
        case 'info-events-list':
          window.location.hash = "infoanlass=1";
          break;
        default:
          break;
      }
    }

    overlayEm.find(".box-content.response-text").remove();
    overlayEm.find(".box-content .content-wrapper.response-text").remove();
    overlayEm.find(".box-content").show();
    overlayEm.find(".box-content .content-wrapper").show();
    overlayEm.addClass('open').trigger('overlay-opened');

    if ($(window).width() < 992) {
      window.scrollTo(0, 0);
    }

    // todo: is this still needed?
    $('.overlay.last-opened').removeClass('last-opened');
    overlayEm.addClass('last-opened');
  }

  // show recaptcha label
  if (overlayEm.find('form').length) {
    window.showReCaptchaLabel();
  }
}

window.closeOverlay = function (overlayEm, isManual, keepPreClosed) {
  if (overlayloading) return;

  window.hideReCaptchaLabel();

  window.go4history.update("job", '');
  preClosedOverlays = keepPreClosed ? preClosedOverlays : [];

  if (typeof overlayEm == "string") {
    overlayEm = $(overlayEm);
  }

  overlayEm.removeClass('open');
  overlayEm.removeClass('last-opened');
  overlayEm.removeClass('has-prev');

  // check if any overlays are pre-closed
  if (window.preClosedOverlays.length > 0 && isManual) {
    var newOverlay = $(preClosedOverlays[window.preClosedOverlays.length - 1]);

    window.openOverlayByName(newOverlay.data('overlay-name'), true);
    window.preClosedOverlays.pop();
  } else if (window.preClosedOverlays.length <= 0 && isManual) {

    $('body').removeClass('overlay-open').removeClass(additionalBodyClass);

    additionalBodyClass = "";

    window.scrollTo(0, savedScrollPos);
  }
}

window.openJobOverlay = function (jobID) {
  window.jobID = jobID;
  window.go4history.update("job", jobID);

  // update Overlay dynamic contents
  let $jobTeaser = $('[data-job-id="' + jobID + '"]');
  let $overlay = $('[data-overlay-name=job-form]');
  let isBlind = $jobTeaser.attr('data-blind');

  const $jobOverlayBoxes = $overlay.find('.job-overlay-boxes');
  if (isBlind) {
    $jobOverlayBoxes.hide();
  } else {
    $jobOverlayBoxes.show();
  }

  $overlay.find('.form-success').css('display', 'none');
  $overlay.find('.form-error').css('display', 'none');
  $overlay.find('.teko-forms').css('display', 'block');

  $overlay.find('.job-location').html($jobTeaser.data('location'));
  $overlay.find('.job-title').html($jobTeaser.find('.job-title').text());
  $overlay.find('.job-description').html(isBlind?'':$jobTeaser.find('.job-description').text().replace(/\n/g, '<br>'));

  if ($jobTeaser.find('.box.icon-time').text()) {
    $overlay.find('.box.icon-time').html($jobTeaser.find('.box.icon-time')
      .text()
      .replace(/\n/g, '<br>'))
      .addClass('show');
  } else {
    $overlay.find('.box.icon-time').removeClass('show');
  }

  if ($jobTeaser.find('.box.icon-calendar').text()) {
    $overlay.find('.box.icon-calendar').html($jobTeaser.find('.box.icon-calendar')
      .text()
      .replace(/\n/g, '<br>'))
      .addClass('show');
  } else {
    $overlay.find('.box.icon-calendar').removeClass('show');
  }

  if ($jobTeaser.find('.box.icon-clock').text()) {
    $overlay.find('.box.icon-clock').html($jobTeaser.find('.box.icon-clock')
      .text()
      .replace(/\n/g, '<br>'))
      .addClass('show');
  } else {
    $overlay.find('.box.icon-clock').removeClass('show');
  }

  if ($jobTeaser.find('.box.hidden-box').text()) {
    $overlay.find('.box.icon-location').html($jobTeaser.find('.box.hidden-box').html()).addClass('show');
  } else {
    $overlay.find('.box.icon-location').removeClass('show');
  }

  window.openOverlayByName('job-form');
}

$(function () {
  $('.overlay-call').on('click', function (e) {
    e.preventDefault();
    let $this = $(this);

    additionalBodyClass = $this.data('overlay-body-class');
    window.openOverlayByName($this.data('overlay-name'));
  });

  // manage click outside of content box
  $(document).on('click', '.overlay:not(.editmode)', function (e) {
    var target = $(e.originalEvent.target);

    if (target.hasClass('overlay-call')) {
      window.closeOverlay($(this), false, true);

    } else if (!target.hasClass('box-inner') && !target.parents('.box-inner').length > 0 && !target.hasClass('close-btn')) {
      // outside -> remove preClosed and close current
      window.closeOverlay($(this), true, false);
    } else if (target.hasClass('close-style') || target.hasClass('close-btn')) {
      // normal close / back
      window.closeOverlay($(this), true, true);
    }
  });

  // Close on ESC click
  $(document).on('keydown', function (e) {
    overlayEm = $('.overlay.last-opened');
    if (e.key == "Escape" && overlayEm.length > 0) {
      window.closeOverlay(overlayEm, true, false);
    }
  });

  // exit-intent modal
  ouibounce(document.getElementById('dummyModal'), {
    cookieExpire: window.exitIntentCookieLifetime,
    sitewide: true,
    callback: function () {
      window.openOverlayByName("exit-intent");
    }
  });

  // open login overlay when param is given
  if (window.location.hash === "#login") {
    window.openOverlayByName("extranet-login");
  }

  // open reset pass overlay
  $(".reset-password > a").click(function () {
    window.openOverlayByName("forgot-pw");
  });
});
