var secondStepOpened = false;

$(function () {
    var $overlayList = $('.overlay[data-overlay-name="info-events-list"]');
    var $overlayForm = $('.overlay[data-overlay-name="info-event-form"]');

    if ($overlayList.length > 0) {
        $(".subscribe-button").on("click", function(e) {
            var $eventItem = $(this).closest(".event-item"),
                infoBox = $eventItem.find(".info-box").html(),
                id = $eventItem.data("id"),
                location = $eventItem.data("location"),
                $infoEventFormOverlay = $(".overlay[data-overlay-name='info-event-form']");

            $infoEventFormOverlay.find(".info-box.copy").html(infoBox);
            $infoEventFormOverlay.find("#infoEvent").val(id);
            $infoEventFormOverlay.find("#location").val(location);

            window.openOverlayByName($(this).data('overlay-name'));
            secondStepOpened = true;
        });

        $(".info-event-list-button").on("click", function() {
            if (!secondStepOpened) {
                window.openOverlayByName("info-events-list");
            } else {
                $('body').addClass("overlay-open");
                $overlayList.addClass('last-opened');
                window.openOverlayByName("info-event-form");
            }
        });

        $(".events li").on("click", function() {
            openInfoEventForm($(this));
        });

        $('.overlay').on('click', function (e) {
            var target = $(e.originalEvent.target);
            if (target.hasClass('close-style') || target.hasClass('close-btn')) {
                secondStepOpened = false;
            }
        });
    }

    // open unsub overlay when param is given
    if (window.getUrlParam("unsubscribehash")) {
        window.openOverlayByName("info-event-unsubscribe");
    }

    var infoeventId;
    if (infoeventId = window.getUrlParam("open-infoevent")) {
        var $elem = $(".events li[data-id='" + infoeventId + "']");
        openInfoEventForm($elem);
    }

    function openInfoEventForm($elem) {
        var html = $elem.html(),
            id = $elem.data("id"),
            location = $elem.data("location"),
            $infoEventFormOverlay = $(".overlay[data-overlay-name='info-event-form']");

        $infoEventFormOverlay.find(".info-box.copy").html(html);
        $infoEventFormOverlay.find("#infoEvent").val(id);
        $infoEventFormOverlay.find("#location").val(location);

        $('body').addClass("overlay-open");
        $overlayList.addClass('last-opened');
        window.openOverlayByName("info-event-form");
    }
});
